.searchBarResults {
  cursor: pointer;
}
.searchBarResults:hover {
  background-color:rgba(0,0,0,0.2);
  opacity: 0.8;
}
.searchDropdown {
  position: absolute;
  z-index: 1;
  top: 2.5rem;
  width: 98% !important;
  height: 10rem;
  overflow-y: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 0.32rem;
}


.loadingWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30vh ;
 }
.searchBarResults {
  display: flex;
  /* flex-direction: column; */
  margin-top: 0.32px;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.8rem;
  font-family: Poppins, sans-serif;
}

.closebtn {
  position: absolute;
  left: 93%;
}
.Mbclosebtn {
  position: absolute;
  left: 92%;
}

.lightdropDown {
  background-color: white !important;
  border: 1px solid #d4d6d6 !important ;
  box-shadow: -10px 10px 8px rgba(192, 191, 191, 0.2),
    10px 10px 8px rgba(192, 191, 191, 0.2);
}

.darkDropdown {
  background-color: #394249;
}

/* Light theme placeholder */
.lightPlaceholder::placeholder {
  color: #837d7d;
}

/* Dark theme placeholder */
.darkPlaceholder::placeholder {
  color: white;
}

.numberofCartItems{
   font-family: Poppins,sans-serif;
   position: absolute;
   color: #ffffff;
   background-color: #ff6636;
   border-radius: 50%;
   padding: 0.1rem 0.5rem;
   font-size: 0.8rem;
   left: 35%;
   top: -25%;
}


.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}




.navbaritems {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin: 0;
}

.leftitems {
  display: flex;
  align-items: center;
}

.hamburgericon {
  color: whitesmoke;
  font-size: 25px;
  display: flex;
  margin-right: 1rem;
}
.hamburgericon,
.svg {
  font-size: 32px;
  cursor: pointer;
}

.img_fit {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.logo {
  height: 4rem;
  width: 13rem;
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/scholarist_orange.png");
}

.rightitems {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.profileimg {
  width: 3.5rem;
  height: 3.5rem;
  background-position: center;
  border-radius: 1.75rem;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
}

.mobileproimg {
  width: 8rem;
  height: 8rem;
  margin-top: 2rem;
}

.browsepart {
  font-size: clamp(0.8rem, 4vw, 1.1rem);
   padding: 0.4rem;
  font-family: Poppins, sans-serif;
  width: 13rem;
   margin-left: 1rem;
 

}
.WtoLearn {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 25rem;
  border: 1px solid;
  border-radius: 0.2rem;
  padding: 0.3rem 0.4rem;
  position: relative;
}

.MobiWtoLearn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 25rem;
  border: 1px solid;
  border-radius: 0.2rem;
  padding: 0.3rem 0.4rem;
  position: relative;
}

.MobBrowsepart {
  font-size: clamp(0.8rem, 4vw, 1rem);
 
  padding: 0.4rem;
  font-family: Poppins, sans-serif;
  width: 16rem;
  flex-shrink: 0;
  /* margin-left: 1rem; */
}

/* bottom part css  */

.maincontainer {
  /* background-color: red; */
  margin: 2rem 1rem;
  padding: 1rem 2rem;
}

.insideContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
}

.mycoursescalender {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.mycourses {
  width: 75%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
}

.gohome {
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  margin-bottom: 0.5rem;
}

.backicon:hover {
  color: #2f80ed !important ;
  text-decoration: underline !important;
}

.allCourse {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  /* max-height: 400px; */
  /* overflow: hidden; */
  /* transition: max-height 4s ease-in-out; */
}
/* smooth transition when user click view all  */
/* .expanded{
   max-height: 2000px;
   transition: max-height 4s ease-in-out;
} */

.myCalender {
  width: calc(100% - 73%);
  padding: 0 0.5rem;

  height: 100%;
  color: white;
}

.calendarcontainer {
  width: 100%;

  background-color: transparent !important;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.gotoSchedule {
  background-color: #ed5c33;
  color: white;
  border: none;
  padding: 0.5rem 3rem;
  margin-top: 3rem;
  border-radius: 1.5rem;
  cursor: pointer;
}
.gotoSchedule:hover {
  background-color: #f67457;
}

.assignmentlesson {
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.assignment {
  width: 75%;
  overflow-x: auto;
}
.assignment::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.assignment::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: rgba(152, 149, 149, 0.267);
}

.upcomingless {
  color: white;
  width: calc(100% - 73%);
  position: relative;
}

/*------------------- NOtification Popover css---------- */
   
  

.notificationText{
 font-family: Poppins,sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-left: 3rem;

}


.notificationContainer{
    width: 100%;
    /* background-color: red; */
    padding:2rem ;

}

.leftContainer{
  display: flex;  
  flex-direction: row;
  gap: 1rem;
}

.rightContainer{
  cursor: pointer;  
}


.eachNotification{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
   padding-bottom: 1rem;
   padding-top: 0.5rem;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
     
}

.logoContaier{
 width: 50px;  
 height: 50px;
 flex-shrink: 0;
}
.logoImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.textContainer{
   font-family: Poppins,sans-serif; 
   /* background-color: red; */
   padding-right: 2rem;
}

.notificationMessage{
     margin: 0; 
     color: #222222;
     font-size: 1.1rem;
     font-weight: 600;
     
    
}
.notificationDate{
    color: #757575;
    font-size: 1rem;
}






/*========================= table css ================ */

.tableStyles {
  color: #dfe7f0;
  width: 100%;
  border-collapse: collapse;
  min-width: 700px; /* Ensure the table doesn't shrink too small */
}

.headerStyles {
  border: none;
  padding: 0 1.1rem 1.1rem 1.1rem !important  ;
  text-align: left;
white-space: nowrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
}

.cellStyles {
  border-bottom: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.99rem;
  padding: 0.8rem 1.1rem !important ;
  vertical-align: middle !important;
 white-space: nowrap;
}
.cessStatus {
  border-bottom: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.88rem;
  vertical-align: middle !important;
  border-radius: 4px !important;
  display: inline-block;
  padding: 0.1rem 0.3rem;
  text-align: center !important;
  margin-top: 0.6rem;
  white-space: nowrap;
}
.completed {
  background-color: #d9eed1 !important ;
  color: #72ad5c !important ;
}
.upcoming {
  background-color: #cbe6f0 !important ;
  color: #4e7584 !important ;
}

.darktable tbody tr:nth-child(odd) {
  background-color: #353535;
}
.lighttable tbody tr:nth-child(odd) {
  background-color: #f5f6f8;
}


.eventdots {
  display: flex;
  justify-content: center;
  margin-top: 10px; 

}

.reddot {
  width: 5px;
  height: 5px;
  /* background-color: red; */
  border-radius: 50%;
  margin: 0 2px;

}




.notificationInMob{
   display: flex;
   align-items: center;
   font-family: Poppins,sans-serif;
   font-size: 1.2rem;
   gap: 1rem;
   cursor: pointer;
}

@media screen and (max-width: 1410px) {
  .rightitems {
    display: none;
  }
  .browsepart {
    display: none;
  }
  .WtoLearn {
    display: none;
  }
  .closebtn {
    display: none;
  }

  .mycoursescalender {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mycourses {
    width: 95%;
  }

  .myCalender {
    width: 50%;
  }

  .assignmentlesson {
    /* background-color: red; */
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
  .assignment {
    width: 85%;
    /* background-color: yellow; */
  }
  .upcomingless {
    display: flex !important;
    /* background-color: green; */
    width: 40%;
  }
}

@media screen and (max-width: 991px) {
  .assignment {
    width: 100%;
  }
  .upcomingless {
    width: 55%;
  }
}

@media screen and (max-width: 776px) {
  .maincontainer {
    padding: 1rem 0.5rem !important;
  }
  .insideContainer {
    padding: 0.5rem 0rem;
  }
  .mycourses {
    width: 100%;
  }

  .myCalender {
    width: 85%;
  }

  .upcomingless {
    width: 65%;
  }
}

@media screen and (max-width: 500px) {
  .MobiWtoLearn {
    width: 18rem;

  }
  .MobBrowsepart {
    width: 14rem;
  }
  .myCalender {
    width: 95%;
  }
  .upcomingless {
    width: 96%;
  }
}

@media screen and (min-width: 1410px) {
  .hamburgericon {
    display: none;
  }
}
