.discussionContainer {
  padding-top: 3rem;
}

.discussionText {
  font-size: 2rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}

.alldiscussions {
}

.individualComments {
  margin-bottom: 2rem;
  font-family: Poppins, sans-serif;
}

.commentHeader {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.profileImageCon {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.profileImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.userName {
  font-size: 1.2rem;
  font-weight: 500;
}

.userRole {
  background-color: #564ffd;
  color: #ffffff;
  padding: 0.2rem 0.5rem;
}

.commentTime {
  color: #737889;
  margin: 0;
  /* padding: 0; */
  font-size: 1.1rem;
}

.userComment {
  padding-left: 4.5rem;
  padding-top: 0.8rem;
  font-size: 1.1rem;
  color: #4e5566;
}

.replayBtn {
  border: none;
  background: none;
  color: #737889;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 4.5rem;
  margin: 0;
}

.giveReplay {
  color: #ff6636;
}

.replayContainer {
  padding-left: 5.2rem;
  padding-top: 1.3rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.replyInputCont {
  border: 2px solid #e9eaf0;
  padding: 0.7rem 1rem;
  display: flex;
  align-items: center;
  width: 80%;
}
.replyInput {
  outline: none;
  border: none;
  font-size: 1.3rem;
  padding-left: 0.3rem;
  color: #4e5566;
  width: 100%;
  background: transparent;
}

.postReplayBtn {
  background-color: #ff6636;
  color: #ffffff;
  border: none;
  font-size: 1.5rem;
  padding: 0.6rem 1rem;
}

.postReplayBtn:hover {
  background-color: #f2734c;
}

.userReplyContainer {
  padding-left: 4.5rem;
  padding-top: 1rem;
}


@media screen and (max-width:1100px) {
    .replyInputCont{
        width: 70%;
    }
}

@media screen and (max-width:810px)   {
      
    .replyInputCont{
        width: 90%;
    }
    .replayContainer{
        flex-direction: column;
    }
}



@media screen and (max-width:600px) {
  .commentHeader{
    gap: 1rem;
  } 
  
  .replyInput{
    font-size: 1.1rem;
  }

  .replyInputCont{
    width: 100%;
}
   .postReplayBtn{
      font-size: 1.1rem;
      padding:0.4rem 0.5rem ;
   }

   .userName{
    font-size: 1rem;
   }
   .userRole{
    padding: 0.1rem 0.4rem;
   }

   .commentTime{
    font-size: 0.9rem;
   }

}