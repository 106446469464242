/*/////////////////////////////////////////// Description css//////////////////////////////////////////////////////////// */
.outerContainer {
  padding: 2.5rem 2rem 3rem 2rem;
  /* background-color: red; */
}

.BasicSaveAndPreviewCon {
  padding: 0 4rem 0.5rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins, sans-serif;
  border-bottom: 2px solid #e9eaf0;
}

.titletext {
  font-size: clamp(1.2rem, 3vw, 1.6rem);
  font-weight: 600;
  white-space: nowrap;
}
.btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.commonbtn {
  padding: 0.7rem 1.3rem;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #ff6636;
  font-weight: 600;
  white-space: nowrap;
}

.btnBgColor {
  background-color: #f6d8cd;
}

.btnBgColor:hover {
  background-color: #fdd3c4;
}

.courseThumbnailCont {
  border-bottom: 2px solid #e9eaf0;
  padding: 2.5rem 2rem;
}

.thumnailContainer {
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  gap: 3rem;
  padding: 0 3rem;
}

.leftPart {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.rightPart {
  padding-top: 3rem;
  /* font-family: Poppins,sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
}
.righttext {
  width: 50%;
  color: #737889;
  font-size: 1.1rem;
  /* background-color: green; */
}

.thumtext {
  font-size: 1.3rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  margin: 0;
}
.thumbimgbox {
  width: 16.25rem;
  height: 15rem;
  background-color: #f5f7fa;

  display: flex;
  align-items: center;
  justify-content: center;
}

.uploaddimgcon {
  width: 16.25rem;
  height: 15rem;
  
}

.uploadthumbnail {
  background-color: #ffeee8;
  color: #ff6636;
  padding: 0.8rem 1.5rem;
  white-space: nowrap;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  display: inline-block;
}
.uploadthumbnail:hover {
  background-color: #fad2c4;
}

/* editor css  */

.editorContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #e9eaf0;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  justify-content: center;
}

.destext {
  font-size: 1.3rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  margin: 0;
}

.texteditor {
  width: 100%;
}

/* Units and Curriculum  */
.unitCurriculumcontainer {
  border-bottom: 2px solid #e9eaf0;
}

.unitCurriculum {
  padding: 2rem 3rem;
}

.textContainer {
  display: flex;

  justify-content: space-between;
}

.addnew {
  color: #ff6c3e;
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.inputContainer {
  position: relative;
}

.labeltext {
  font-family: Poppins, sans-serif;
  padding-bottom: 0.3rem;
  font-size: 1rem;
  margin: 0;
}
.inputtext {
  outline: none;
  padding: 0.6rem 0.4rem 0.6rem 0.7rem;
  font-size: 1.1rem;
}

.darktext {
  border: 1px solid #ffffff;
  background-color: #11151a;
  color: white;
}
.lighttext {
  border: 1px solid #dee0e2;
  background-color: #ffffff;
}
.lighttext::placeholder {
  color: #aab0bb;
  font-family: Poppins, sans-serif;
}
.darktext::placeholder {
  color: white;
}

.textlength {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 80%;
  transform: translateY(-80%);
  font-size: clamp(0.8rem, 3vw, 1rem);
}

.bottomBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 3.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.commomBottombtn {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 0.9rem 2rem;
  border-radius: 1.5rem;
  cursor: pointer;
  white-space: nowrap;
}

.cancel {
  background-color: #ffffff;
  color: #757b89;
  border: 1px solid #757b89;
}
.cancel:hover {
  background-color: #e6e4e4;
}

.saveNext {
  background-color: #ff6636;
  color: #ffffff;
}

.saveNext:hover {
  background-color: #fa774f;
}

/*//////////////////////////////// Course Curriculum css /////////////////////////////////////////////////////////////// */

.sectionContainer {
  /* background-color: red; */
  padding: 3rem 4rem;
}

.allSections {
  background-color: #edf2f9;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.sectionTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.textsCon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
}

.sectionText{
   font-size: 1.2rem;
   font-weight: 600;
   color:#1d2026 ;

} 

.sectionName{
   font-size: 1.1rem;
   color: #22252a;
}

.iconSize{
  /* text-align: center; */
  display: inline-block;
  cursor: pointer;

}


.iconContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #8c94a3;
}




.eachLesson {
  background-color: #ffffff;
  margin-bottom: 0.8rem;
  margin-top: 1rem;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lessonfirstpart {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.lessonsecondpart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #8c94a3;
}

.lessonName{
   font-size: 1rem;
}

.contents {
  background-color: #fde4db;
  color: #ff6636;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  font-size: 1.1rem;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  padding: 0.5rem 0.7rem;
  border-radius: 23px;
  cursor: pointer;
}

.addSectionbtn {
  margin-top: 2rem;
  background-color: #fde4db;
  color: #ff6636;
  text-align: center;
  font-size: 1.2rem;
  padding: 0.7rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 5rem;
}

.addSectionbtn:hover {
  background-color: #f8cfc0;
}

.currsaveandnext {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3.5rem;
}

.popovertext {
  gap: 0.3rem;
  align-self: flex-start;
  color: #667073;
  cursor: pointer;

  }



  .commontbtnToadd {
    padding: 0.6rem 1.3rem;
    cursor: pointer;
    font-size: 1.1rem;
    text-align: center;
    outline: none;
    border: none;
    font-family: Poppins, sans-serif;
    border-radius: 5px;
  }
  
  .canclebtn {
    background-color: rgb(182, 178, 178);
    color: black;
    font-weight: 600;
  }
  .canclebtn:hover {
    background-color: rgb(216, 201, 201);
  }
  .submitbtn {
    background-color: #ff6636;
    color: white;
    font-weight: 600;
  }
  .submitbtn:hover {
    background-color: #f76d43;
  }

/*//////////////////////////////////////// CurriculumSchedule//////////////////////////////////////////////////// */







@media screen and (max-width: 1330px) {
  .outerContainer {
    padding: 2.5rem 0rem 3rem 0rem;
  }

  .courseThumbnailCont {
    padding: 2.5rem 1rem;
  }
  .thumnailContainer {
    padding: 0rem 1rem 0 3rem;
  }
  .righttext {
    width: 80%;
  }

  .sectionContainer{
         padding:3rem 0rem ;
  }
}

@media screen and (max-width: 991px) {
  .BasicSaveAndPreviewCon {
    padding: 0.3rem 1rem 0.5rem 1rem;
  }

  .btnContainer {
    gap: 1rem;
  }

  .commonbtn {
    padding: 0.5rem 1.1rem;
  }

  .thumtext {
    font-size: 1.2rem;
  }

  .courseThumbnailCont {
    padding: 2.5rem 0.5rem;
  }
  .thumnailContainer {
    padding: 0rem 0rem 0 2.5rem;
  }
  .righttext {
    width: 100%;
  }

  .thumbimgbox {
    width: 14rem;
    height: 13rem;
  }
  .uploaddimgcon {
    width: 14rem;
    height: 13rem;
  }

  .editorContainer {
    padding: 2rem 1.5rem;
  }

  .unitCurriculum {
    padding: 2rem 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .BasicSaveAndPreviewCon {
    padding: 0rem 0rem 0.5rem 0rem;
    flex-direction: column;
  }

  .btnContainer {
    gap: 1rem;
    align-self: last baseline;
  }
  .titletext {
    align-self: first baseline;
  }
  .commonbtn {
    padding: 0.4rem 0.9rem !important;
    font-size: 1rem;
  }

  .thumnailContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0rem 0rem 0rem 0rem;
  }

  .thumtext {
    font-size: 1.3rem;
  }
  .thumbimgbox {
    width: 16rem;
    height: 15rem;
  }
  .uploaddimgcon {
    width: 16rem;
    height: 15rem;
  }
  .rightPart {
    padding-top: 1.5rem;
  }

  .righttext {
    padding-top: 0rem;
  }

  .righttext {
    width: 95%;
  }

  .uploadthumbnail {
    align-items: center !important;
  }

  .bottomBtn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .commomBottombtn {
    font-size: 0.9rem;
    padding: 0.6rem 1rem !important ;
  }

  .leftPart {
    flex-direction: column-reverse;
  }

  .thumtext {
    font-size: 1.3rem !important;
  }

  .unitCurriculum {
    padding: 2rem 0.5rem;
  }
    

  .allSections{
    padding: 2rem 1rem;
  }

  .contents{
      padding: 0.3rem 0.4rem;
      font-size: 1rem;
  }



}

@media screen and (max-width: 576px) {
  .thumbimgbox {
    width: 13rem;
    height: 12rem;
  }
  .uploaddimgcon {
    width: 13rem;
    height: 12rem;
  }
  .righttext {
    width: 100% !important;
    font-size: 1rem !important;
  }
  .rightPart {
    padding-top: 1.5rem;
    /* background-color: red; */
  }

  .uploadthumbnail {
    padding: 0.6rem 1.3rem;
    font-size: 1.1rem;
  }
  .titletext {
    padding-bottom: 1rem;
  }

  .destext {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .addnew {
    font-size: 1rem;
  }

  .inputtext {
    font-size: 0.9rem;
    padding: 0.4rem 0.2rem 0.4rem 0.4rem;
  }

  .editorContainer {
    padding: 2rem 0.5rem;
  }

  .sectionTitleContainer{
       flex-direction: column;
       gap: 1rem;
      
  }

  .textsCon{
     align-self: first baseline;
  }
  .iconContainer{
     align-self: last baseline;
  }

 
  





 .sectionText{
    font-size: 0.9rem !important ;
 }
 .sectionName{
  font-size: 0.9rem;
   }  

 

  .lessonName{
     font-size: 0.9rem;
  }
 
  .contents{
    font-size: 0.8rem;
  }
   
.eachLesson{
   flex-direction: column;
   padding: 0.2rem 0.2rem;
   gap: 1rem;
}

.lessonfirstpart{
   align-self: first baseline;
}

.lessonsecondpart{
     align-self: last baseline;
}


}

@media screen and (max-width: 400px) {
  .inputtext {
    font-size: 0.7rem;
  }

  .destext {
    font-size: 1rem;
  }

  .addnew {
    font-size: 0.9rem;
  }

  .unitCurriculum {
    padding: 2rem 0rem;
  }

  .editorContainer {
    padding: 2rem 0rem;
  }

  
 


}


