/* Container styles */
.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    text-align: center;
  }
  
  /* Spinner styles */
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 1.5rem;
  }
  
  /* Animated dots */
  .dot {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  
  .dot-1 {
    background-color: #ff6f61; 
    animation-delay: 0s;
  }
  .dot-2 {
    background-color: #ffd700;
    animation-delay: 0.3s;
  }
  .dot-3 {
    background-color: #32cd32; 
    animation-delay: 0.6s;
  }
  
  /* Animation for dots */
  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  
  /* Text styles */
  .spinner-text {
    font-family: Poppins,sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  