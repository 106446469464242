@keyframes moveup {
  0% {
  }
  100% {
    transform: translateY(-3rem);
  }
}
.extra_ctr {
  background-color: black;
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/black_cover.svg");
  background-size: cover;
  /* min-height: 98vh; */
  padding-bottom: 7rem;
}
.wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 80px;
}

.wave .shape-fill {
  fill: #ffffff;
}
.demo_img {
  height: 0;
  width: 70%;
  padding-bottom: 70%;
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/AVATAR.png");
  opacity: 1;
  transform: scale(1.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  animation: 2s infinite alternate moveup;
}

.chat_icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/*   css for  Expert Guidance ,Interactive Classes and Resources and Materials part  */

.hangingBox {
  position: relative;
  z-index: 100;
  margin-top: -4rem;
  background-image: linear-gradient(
    235deg,
    rgb(14, 32, 68),
    rgb(14, 32, 68),
    rgb(14, 32, 68)
  );
  background-size: cover;
  border-radius: 20px;
  padding: 2.5rem 3rem;
  text-align: left;
  transition: all 0.3s ease-in-out;
}

.icongbackground {
  background-color: rgba(244, 234, 234, 0.4);
  border-radius: 10px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  padding: 0.5rem;
  width: 3.25rem;
  height: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex-shrink: 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -0.3rem;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
    font-family: Poppins,sans-serif;
   max-width: 100%;  
    word-wrap: break-word;
}

.descriptionText{
  font-size: 0.9rem !important;
}



.textContainer h4 {
  margin: 0;
  font-size: 1.5rem;
  color: white;
  /* white-space: nowrap; */
  max-width: 100%;
}

.textContainer p {
  padding: 0;
  margin-top: 5px;
  font-size: 0.9rem;
  color: #9199a9;
}

/*  */
.heading1,.heading2,.heading3 {
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.heading2 {
  color: #fc5a03;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.heading3 {
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.heading1 {
  font-size: clamp(
    35px,
    5vw,
    62px
  ); /* Adjusts between 35px and 60px depending on viewport size */
}
.heading2,
.heading3 {
  font-size: clamp(35px, 5vw, 52px);
}

.width_heading {
  width:'33%$'
}

@media (max-width: 991px) {
  .textContainer h4 {
    font-size: 1.1rem;
  }

  .textContainer p {
    font-size: 0.85rem;
    /* padding: 0.4rem; */
    padding: 0;

  }
  .width_heading {
    width:auto
  }
}

@media (max-width: 768px) {
  .textContainer {
    margin-left: 10px;
  }

  .textContainer h4 {
    font-size: 1rem;
  }

  .textContainer p {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .textContainer {
    margin-left: 8px;
  }

  .textContainer h4 {
    font-size: 1.2rem;
  }

  .textContainer p {
    font-size: 0.82rem;
    /* padding: 0; */
  }
 
}



