.avatar {
    height:2rem;
    width:2rem;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}
.messageUser {
    max-width: 48%;
    height:auto;
     background-color: #f67860;
    color:white;
    min-height: 1em;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    font-family: Poppins,sans-serif;
}
.messageSender {
    max-width: 48%;
    height:auto;
    background-color: #f3f4f6;
    color:black;
    min-height: 1em;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
}
.scroll{
    overflow-x: hidden;
    overflow-y: scroll;
    flex: 1 1 auto
}
.w_98{
    width:98%
}
@media screen and (min-height:800px){
   
}