.tutorsection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagcontainer {
  display: flex;
  position: relative;
  flex: 1;
  align-items: flex-end;
  justify-self: flex-end;
  height: auto;
  overflow: hidden;
  margin-bottom: -100px;
  height: 500px;
}
.imagcontainer img {
  width: 100%;

  height: 80%;
  object-fit: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-position: center;
}
