.w_25{
min-width:30%;
cursor:pointer;
}
.decor{
    text-decoration: none !important;
    color:black;
}
.decor:hover{
color:black;
}
.img{
background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/alie.jpeg');
width:6rem;
height:6rem;
border-radius:50%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
position:relative;
top:3rem
}
.rating{
    color:rgba(173,58,63)
}
.courseinstructor{
    color:rgb(179,181,183)
}

@media screen and (min-width:1000px){
    .img{
        background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/alie.jpeg');
        width:8rem;
        height:8rem;
        border-radius:50%;
        background-size: cover;
        background-repeat: no-repeat;
        position:relative;
        top:4rem
        }
}