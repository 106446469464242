

.mainbgcontainer {
  /* background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/CourseHeroBg.png"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.imgdesc {
  display: flex;
  flex-direction: row;
  padding: 5rem 6rem 0rem 6rem;
  gap: 2rem;
}

.activeClass{
  background-color: rgb(216, 214, 214);
  /* background-color: rgb(100, 97, 97); */
}
.darkactivClass{
  background-color: rgb(100, 97, 97);
}


.pdffile{
   margin-top: 1.5rem;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textcontainer {
  margin-top: 2rem;
  width: 45%;
}

.advanctext {
  font-size: clamp(33px, 4vw, 50px);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.lowertext {
  font-size: clamp(33px, 4vw, 50px);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.description {
  /* color: #4d4d4d; */
  font-size: clamp(16px, 4vw, 20px);
  font-size: Poppins, sans-serif;
}

.trialbtn {
  background-color: #fd661f;
  color: white;
  padding: 5px 20px;
}

.trialbtn:hover {
  color: white !important;
}

.imageContainer {
  width: 55%;
}

.imge {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.coursescontainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 3rem 1.25rem;
}

.scrollarrow {
  background: none;
  border: none;
  color: #f5690c;

  font-size: clamp(25px, 4vw, 35px);
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.leftarrow {
  left: 0;
  /* left: 20px;  */
}

.rightarrow {
  right: 3%;
  /* right: 20px;  */
}

.courseslistwrapper {
  overflow-x: auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;

  display: flex;
}

.courseslist {
  width: 100%;
  display: flex;
  gap: 5rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px;
  scrollbar-width: none;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}

.courseslist::-webkit-scrollbar {
  display: none;
}

.coursebtn {
  color: #636263;
  display: flex;
  font-weight: 700;
  font-size: clamp(1.2rem, 4vw, 1.4rem);
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.5s ease, color 0.5s ease, border 0.5s ease;
  position: relative;

  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif !important;
}

.darkactive {
  color: white;
}
.darkactive::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleX(1.6);
  bottom: -10px;
  height: 3px;
  background-color: #ff6636;
  width: 100%;
  transition: transform 0.5s ease;
  z-index: 1;
}
.activeclass {
  color: #1d2026;
}

.activeclass::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleX(1.6);
  bottom: -10px;
  height: 3px;
  background-color: #ff6636;
  width: 100%;
  transition: transform 0.5s ease;
  z-index: 1;
}

/*////////////////////////////////////// unit description css start here///////////////////////////////////// */

.unitDescriptionContainer {
  padding: 4rem 5rem 5rem 5rem;
  display: flex;
  flex-direction: row;
}

.allUnitsCon {
  display: inline-flex;
  flex-direction: column;
  gap: 2rem;
}

.unitName {
  padding: 1rem 2rem;
  font-size: clamp(1.1rem,4vw,1.3rem);
  border: 2px solid #dedede;
  font-weight: 500;
  border-radius: 0.3rem;
  cursor: pointer;
  display: inline-flex;
  
  min-width: 20rem;
}

.leftDescriptionPart {
  width: 30%;
}

.rightDescriptionPart {
  width: calc(100% - 30%);
}

/* .videoDetailsContainer{
     
} */

.videoContainer {
  width: 100%;
  height: 35.75rem;
}

.videoTitle {
  font-family: Poppins, sans-serif;
  font-size: 2rem;
  font-weight: 600;
  padding-top: 2rem;
}

.peopleAndComments {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #e9eaf0;
}

.leftOne {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.rightOne {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.numberofWatching {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.navIteminforVid {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  padding-top: 2rem;
  font-family: Poppins, sans-serif;
  border-bottom: 3px solid #e9eaf0;
  padding-bottom: 0.7rem;
}

.noOfAttachfile {
  background-color: #f7dbd1;
  color: #ff6636;
  padding: 0.2rem 0.4rem;
  font-weight: 600;
  text-align: center;
}

.singleItem {
  /* color: #6e7485; */
  font-size: 1.2rem;
  cursor: pointer;
  white-space: nowrap;
}

.navVidActiveClass {
  position: relative;
}

.navVidActiveClass::after {
  content: " ";
  position: absolute;
  left: 50%;
  bottom: -1.8rem;
  transform: translateX(-50%) scaleX(1.6);
  height: 0.15rem;
  width: 80%;
  z-index: 1;
  background-color: #ff6636;
}

.lectureDescriptionContainer {
  padding-top: 5rem;
  font-family: Poppins, sans-serif;
}

.lecturDesText {
  font-size: clamp(1rem ,4vw,1.5rem);
  font-weight: 600;
}

.lectureDes {
  font-size: 1.2rem;
}

.secondDes {
  padding-top: 1rem;
  font-size: 1.3rem;
}

.lectureNotesCont {
  padding-top: 3rem;
  font-family: Poppins, sans-serif;
}

.notesandDwnbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lecturNotes {
  font-size: 2rem;
  font-weight: 600;
}

.downloadbtn {
  outline: none;
  border: none;
  background: transparent;
  background-color: #ffeee8;
  color: #ff6636;
  font-size: 1.3rem;
  padding: 0.8rem 1.2rem;
  font-weight: 500;
}

.lecturefirstdes {
  font-size: 1.2rem;
  padding-top: 0.5rem;
}

.attachFileCont {
  padding-top: 2rem;
}

.attachfileText {
  font-family: Poppins, sans-serif;
  font-size: 2rem;
  font-weight: 500;
}

.templateAndDownfile {
  margin-top: 2rem;
  background-color: #eaf0f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.3rem 2.5rem;
}

.templateFile {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.testTemText {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 500;
}

.downloadFile {
  background-color: #ff6636;
  color: #ffffff;
  border: none;
  font-size: 1.5rem;
  padding: 0.6rem 1.8rem;
}

.downloadFile:hover {
  background-color: #f57650;
}

/* unit description media query */

@media screen and (max-width: 1450px) {
  .unitDescriptionContainer {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 3rem 1.5rem 5rem 1.5rem;
  }

  .leftDescriptionPart {
    width: 90%;
    
    align-self: center;
  }
  .allUnitsCon {
    display: flex;
    /* display: inline-flex; */
      align-self: center !important;
      justify-content: center;
  }

  .rightDescriptionPart {
    width: 95%;
  }
}

@media screen and (max-width: 900px) {

  .pdffile{
    width: 100%;
  }
  .videoContainer {
    height: 25rem;
  }

  .peopleAndComments {
    flex-direction: column;
    gap: 1rem;
  }
  .leftOne {
    align-self: first baseline;
  }
  .rightOne {
    align-self: last baseline;
  }

  .navIteminforVid {
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 1rem;
  }

  .navIteminforVid::-webkit-scrollbar {
    display: none;
  }

  .lecturDesText {
    font-size: 1.7rem;
  }

  .lectureDes {
    font-size: 1.1rem;
  }

  .secondDes {
    font-size: 1.1rem;
  }

  .lecturNotes {
    font-size: 1.7rem;
  }

  .downloadbtn {
    font-size: 1.2rem;
    padding: 0.6rem 1rem;
  }

  .lecturefirstdes {
    font-size: 1.1rem;
  }

  .attachfileText {
    font-size: 1.7rem;
  }

  .testTemText {
    font-size: 1.4rem;
  }

  .downloadFile {
    font-size: 1.3rem;
    padding: 0.4rem 1.6rem;
  }
}

@media screen and (max-width: 630px) {
  .videoContainer {
    height: 17rem;
  }

  .rightDescriptionPart {
    width: 100%;
  }

  .rightOne {
    flex-direction: column;
    gap: 0rem;
    align-items: center;
    justify-content: center;
    align-self: first baseline;
  }
  .peopleAndComments {
    gap: 2rem;
  }

  .notesandDwnbtn {
    flex-direction: column;
  }

  .templateAndDownfile {
    flex-direction: column;
    gap: 2rem;
  }
}

/* upper media query */

@media screen and (max-width: 1024px) {
  .imgdesc {
    flex-direction: column-reverse;
    gap: 0rem;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .textcontainer {
    /* background-color: red; */
    width: 80%;
    margin-top: -1rem;
  }

  .imageContainer {
    /* background-color: green; */
    width: 80%;
    padding-top: 2rem;
  }

  .coursescontainer {
    padding: 3rem 2rem;
  }
  .courseslistwrapper {
    padding: 0.5rem 2rem;
  }

  .courseslist {
    justify-content: flex-start;
    gap: 2.5rem;
    padding: 0.625rem 0rem;
  }
}

@media screen and (min-width: 810px) and (max-width: 1024px) {
  .courseslistwrapper {
    margin-left: 5rem;
  }
}
