.teacherMainContainer{
      padding: 0 12rem;
}


.coursestext {
    /* font-size: 3rem;  */
    font-size: clamp(1.8rem, 4vw, 3rem); 
    font-weight: bold;
    color: #fd661f;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    position: relative;
    text-align: center;
    display: inline-block;  
    /* margin-bottom: 1rem;   */
}

.ropesvg {
    position: absolute;
  
    transform: translateX(-50%);
    width: 90%; 
    height: auto;
     top: 45%; 
    left: 97%;
  
}


.truncated {
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    }

.coursebtn {
    background: white;
    color: #717072;
    border: 2px solid #c3c2c4;
    padding: 0.4rem 1.2rem !important;
    border-radius: 0.5rem !important;
    display: flex;
     font-family: Poppins,sans-serif !important;
    cursor: pointer;
    margin: 0 10px 0 10px;
    transition: background-color 0.5s ease, color 0.5s ease, border 0.5s ease;
    white-space: nowrap;
}

.activeclass{
       background-color:#9e35cc ;
       color: white;
       border: none;
}

.coursescontainer {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    width: 100%;
    /* background-color: green; */
    padding: 0 1.25rem; 
}

.courseslistwrapper {
  /* background-color: red; */
    overflow-x: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem  1.25rem; 
  
}

.courseslist {

    display: flex;
    /* gap: 10px; */
    gap: 0.625rem;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 10px;
    scrollbar-width: none;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start;
   
}

.courseslist::-webkit-scrollbar {
    display: none;
}

.scrollarrow {

    background: none;
    border: none;
    color: #f5690c;
    /* font-size: 35px; */
    font-size: clamp(25px,4vw,35px );
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.leftarrow {
    left: 0;
    /* left: 20px;  */
}

.rightarrow {
    right: 0;
    /* right: 20px;  */
}


@media screen and (max-width:1500px) {
        .teacherMainContainer{
              padding: 0 5rem;
        }
}


@media screen and (max-width:768px) { 
   .courseslistwrapper{
    width: 100%;
    overflow-x: auto;

   } 
   .courseslist{
     width: 100%;
     overflow-x: auto;
   }
    
}

@media screen  and (max-width:576px)  {
     .coursecontent{
         padding: 0 0 0 1.4rem;
     } 

     .teacherMainContainer{
        padding: 0 0.5rem;
  }
}






