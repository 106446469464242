.outerContainer {
  padding: 2.5rem 3rem 3rem 3rem;
 
}

.BasicSaveAndPreviewCon {
 padding: 0rem  2.2rem 0.5rem 2.2rem;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 font-family: Poppins, sans-serif;
 border-bottom: 1px solid #e9eaf0;
 /* gap: 1.5rem ; */
}

.titletext {
 font-size: clamp(1.6rem, 3vw, 1.8rem);
 font-weight: 600;
 white-space: nowrap;
}

.btnContainer {
 display: flex;
 align-items: center;
 justify-content: center;
 gap: 2rem;
}

.commonbtn {
 padding: 0.6rem 1.1rem;
 letter-spacing: 1px;
 cursor: pointer;
 font-size: 1.1rem;
 color: #ff6636;
 font-weight: 600;
 white-space: nowrap;
}

.btnBgColor {
 background-color: #f6d8cd;
}

.btnBgColor:hover {
 background-color: #fdd3c4;
}

.allFields {
 /* background-color: red;  */
   padding: 2rem 3rem;
   display: flex;
   flex-direction: column;
   gap: 2rem;

}

.labeltext {
 
 font-family: Poppins, sans-serif;
 padding-bottom: 0.3rem;
 font-size: 1rem;
 margin: 0;
}

.inputtext {
 outline: none;
 padding: 0.6rem 0.4rem 0.6rem 0.7rem;
 font-size: 1.1rem;
}

.labelpricetext{
 font-family: Poppins, sans-serif;
 /* padding-bottom: 0.3rem; */
 margin-top: -0.5rem !important;
 font-size: 1rem;
 margin: 0;
}
.inputprice{
 outline: none;
 padding: 0.6rem 0.4rem 0.6rem 0.7rem;
 font-size: 1.1rem; 
}

.darktext {
 border: 1px solid #ffffff;
 background-color: #11151a;
 color: white;
}
.lighttext {
 border: 1px solid #dee0e2;
 background-color: #ffffff;
}
.lighttext::placeholder {
 color: #aab0bb;
 font-family: Poppins, sans-serif;
}
.darktext::placeholder {
 color: white;
}

.inputContainer {
 position: relative;
}

.textlength {
 cursor: pointer;
 position: absolute;
 right: 1rem;
 top: 80%;
 transform: translateY(-80%);
 font-size: clamp(0.8rem, 4vw, 1rem);
}

.firstSelectCont{
    display: flex; 
    flex-direction: row;
    gap: 1.5rem;
}
.courseCategory{
width: 50%;


}

.subCategory{
 width: calc(70% - 21%);
}


.fourSelectContainer{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}

.firstTwo{
 width: 50%;
 display: flex;
 flex-direction: row;
 gap: 1.5rem;

}
.lastTwo{
 width: calc(70% - 21%); 
 display: flex; 
 flex-direction: row;
 gap: 1.5rem;
}

.courseLanguage{
    width: 50%;
}
.subTittleLanguage{
 width: calc(70% - 21%); 
}


.courseLevel{
 width: 50%;
}
.durations{
 width: calc(70% - 21%); 
}



.bottomBtn{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
    padding-top: 1.5rem;
}

.commomBottombtn{
  font-family: Poppins,sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 0.9rem 2rem;
  border-radius: 1.5rem;
  cursor: pointer;

}

.cancel{
   background-color: #ffffff;
   color: #757b89;
   border: 1px solid #757b89;
}
.cancel:hover{
  background-color: #e6e4e4;
}

.saveNext{
background-color: #ff6636;
color: #ffffff ;
}

.saveNext:hover{
  background-color: #fa774f;
}


@media screen and (max-width:1330px) {
     .outerContainer{
       padding: 2.5rem 0rem 3rem 0rem;
     }

     .allFields{
        padding: 2rem  2rem !important;
     }
}


@media screen and (max-width:1200px) { 

  .fourSelectContainer{
     display: flex;
     flex-direction: column;
  }

  .firstTwo{
      width: 100%;
  }
  .lastTwo{
     width: 100%;
  }
 
}


@media screen and (max-width:768px) {
     

  .BasicSaveAndPreviewCon{
     padding: 0rem 0rem 0.5rem 0rem;
     flex-direction: column;
  }


  .btnContainer{
    gap: 1rem;
    align-self: last baseline;
  }
  .titletext{
   align-self: first baseline;
  }

  .commonbtn{
    padding: 0.4rem 0.9rem !important;
    font-size: 1rem;
  }





 .allFields{
     padding: 2rem 0rem !important;
 }
  
 .firstSelectCont,.firstTwo,.lastTwo{
    flex-direction: column;
 } 
 .courseCategory,.subCategory,.courseLanguage,.subTittleLanguage,.courseLevel,.durations{
   width: 100%;
   }



 .commomBottombtn{
    font-size: 1rem !important;
    padding: 0.6rem 1rem !important;
 }

   
     


}