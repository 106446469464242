

.commoniconn{
    display: flex;
    gap: 0.8rem;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    cursor: pointer;
    padding: 0.7rem 1.4rem;
    text-decoration: none;
    border-radius: 1.5rem;
    transition: background-color 0.5s ease;

} 

.review {
  width:90%;
  height:0;
  padding-bottom: 90%;
  background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/dashboard.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.commoniconn:hover{
  background-color: rgb(0, 0, 0,0.1);
}

.textcolorsize{
 color: #a3afca;
 font-size: 1.1rem !important;
 font-weight: 600;
 font-family: Poppins,sans-serif;
}
.activetext{
  color: white;
}

.active {
  background-color: rgb(0, 0, 0,0.3);
   
}

.canvascontainer{
   display: flex;
   margin-left: -3rem;
   box-shadow: 8px 0 10px rgba(192,191,191,0.3);
}
.canvascontainer::-webkit-scrollbar{
  width: 0.5rem;
  height: 0.5rem;
}
.lighscrollbar::-webkit-scrollbar-thumb{
  border-radius:0.5rem ;
  background-color:rgba(6, 6, 6, 0.44) ;
}

.darkscrollbar::-webkit-scrollbar-thumb{
  border-radius:0.5rem ;
  background-color:rgba(241, 234, 234, 0.267) ;
}








