.poppins{
  font-family: 'Poppins', sans-serif;;
}
.rounded-full {
  border-radius: 100%;
}
img{
  display: inline-block !important;
  margin-bottom: 6rem !important;
}
#admin_wrapper {
  overflow-x: hidden;
  /* background-color: rgb(17,21,26); */
  /* background-image: linear-gradient(0deg,#3b4446 ,rgba(0,0,0,1)); */
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
#wrapper {
  /* overflow-x: hidden; */
  /* background-color: rgb(27,31,36);   */
  /* background-image: linear-gradient(0deg,#3b4446 ,rgba(0,0,0,1)); */
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
#wrapperWhite {
  overflow-x: hidden;
  background-color: rgb(255,255,255);
  /* background-image: linear-gradient(0deg,#3b4446 ,rgba(0,0,0,1)); */
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.rmdp-input {
  padding:2rem !important;
  font-size: large !important;
}
#page-content-wrapper {
  min-width: 100vw;
}
.offcanvas {
  width: 250px!important;
  max-width:100%;
  overflow-y: scroll;
}
.border-black {
  border-color: black !important;
}
.list-group-item {
  border: none;
  cursor:pointer;
}
.profile-icon{
  width:40%;
  height:0;
  padding-bottom: 40%;
}

.user-image{
  width:50%;
  height:0;
  padding-bottom:50%;
  position: relative;
  top:-3rem;
  z-index: 10;
}
.profile-icon,.user-image{
  border-radius: 50%;
  background-size: cover;
  cursor: pointer;
}
.user-image{
  background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/banvir.jpeg');
}
.list-group-item.active {
  background-color: rgba(250,101,102,0.3);
  color: var(--main-text-color);
  font-weight: 900 !important;
  border: none;
}
.list-group-item.active_purple {
  background-color: rgb(120,112,202);
  color: var(--main-text-color);
  font-weight: 400 !important;
  border: none;
}

.list-group-item.active_purple:hover {
  color:black
}

.list-group_item.active_purple {
  background-color: rgb(120,112,202);
  color: var(--main-text-color);
  font-weight: 900 !important;
  border: none;
}

.red-dot::after {
  content:'';
  position: absolute;
  right: 2%;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

.bg-green{
  background-image: linear-gradient(135deg,#0f3c01,#b3eaa2);
}
.courses-progress,.courses-upcoming,.courses-balance{
  width: 40%;
  height:0;
  padding-bottom: 40%;
  border-radius: 50%;
  background-size: cover;
}
.courses-progress{
  background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/bulb.svg');
}
.courses-upcoming{
  background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/bookmark.svg');
}
.courses-balance{
  background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/balance.svg');
}
.bg-yellow{
  background-image:linear-gradient(135deg,#c08300,#ffd476); 
}
.bg-purple{
  background-image: linear-gradient(150deg, rgb(80,32,164),rgb(225,186,230));
}
.bg-red{
  background-image: linear-gradient(165deg,rgb(164,36,22),rgb(215, 146, 138));
}
.bg-blue{
  background-image: linear-gradient(120deg,rgb(18, 101, 218),rgb(156, 190, 237));
}
.bg-orange{
  background-image: linear-gradient(135deg, rgb(241, 116, 44),rgb(214, 173, 150));
}
.bg-simple-purple{
  background-color:purple;
}
.bg-simple-orange{
  background-color:rgb(241, 163, 18) ;
}
.bg-simple-yellow{
  background-color: #ebc062;
}
.color-grey{
  color:grey;
}
.bg-customblack{
  background-color: rgba(40,40,40,0.8);
}
.teacher-icon{
  height:3rem;
  width:3rem;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.bg-customgrey{
  background-color: rgb(241, 244, 244);
}

.react-date-picker{
  background-color: #fff;
  border-radius: 0.2rem;
  width:100%
}
.react-date-picker__wrapper{
  border-radius: 0.2rem;
  padding:0.4rem;
  border-color: rgb(207,212,217) !important;
}

.course-image{
  width:90%;
  height:0;
  padding-bottom: 90%;
  border-radius: 6%;
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center;
  background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/future.svg');
}
.course-image2{
  width:90%;
  height:0;
  padding-bottom: 90%;
  border-radius: 6%;
  background-size: cover;
  background-repeat:no-repeat;
  background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/future.svg');
}
.btn_blue{
  background-color: rgb(180, 208, 241) ;
  color:white;
  
}  
.border-orange{
  border-color: rgb(236,135,78) !important;
  border-width: 2px;
}
.scroll-full{
  overflow-x: scroll;
  overflow-y: scroll;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;
}

.list-group_item {
  text-decoration: none;
  color:rgb(125,130,155)
}

.list-group_item:hover {
  text-decoration: none;
  color:rgb(210,210,210);
}

.scroll-full::-webkit-scrollbar{
  display: none !important;
  width:0 !important;
}
.nav-link.active{
  background-color: transparent !important;
  color: rgb(237,134,35) !important;
  font-weight: bold;
  border-color:  rgb(237,134,35) !important;
}
.nav-link._active{
  background-color: rgb(50,57,67)!important;
  font-weight: bold;
  
}

@media (min-width: 768px) {

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  .offcanvas {
    width: 300px!important;
    max-width:100%;
  }

}

@media (min-width:992px){
  .course-image{
    width:100%;
    padding-bottom: 100%;
  }
  .course-image2{
    width:100%;
    padding-bottom: 100%;
  }
}

@media screen and (max-width:776px) {
  .offcanvas {
      width: 320px!important;
      max-width:100%;
    }
}