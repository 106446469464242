.instuctorContainer{
    min-height: 85vh;

    padding: 5rem 0rem 5rem 0rem;

}

.upperSectionTutor{
      padding: 0rem 6rem;
      display: flex;
      flex-direction: row;
      gap: 2rem;
}


.leftInstuctorpart{
   width: 45% !important; 
   padding: 2rem 2rem 0 2rem;
   /* background-color: green; */
   
}

.rightInstuctorPart{
   /* background-color: red; */
width: calc( 100% - 45%)   !important;
}


.bAnIntext{
   font-size: 3rem !important;
   font-family: Poppins,sans-serif;
  font-weight: bold;
}

.instDescription{
   color: #4e5566  !important;
   font-family: Poppins,sans-serif;
   font-size: 1.5rem  !important; 
}

.tutorProfilebtn{
     border: none;
   background-color: #ff6636;
   color: #ffffff;
   font-size: 1.6rem;
   padding: 0.5rem 2.5rem;
   margin-top: 1rem;

}

.tutorProfilebtn:hover{
   background-color: #f17a56;
}


.InstuctorImageCont{
 
   width: 100%;
   height: auto;
    
  
}

.InstuctorImage{
   width: 100%;
   height: 100%;
   background-size: cover;
}







.sCICLSRContainer{
  background-color:#ffeee8 ;   
   padding: 3rem 14rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}

.firstTwoCon{
display: flex;
flex-direction: row;
justify-content: space-around;
width: 50%;
}

.lastTwoCon{
display: flex;
flex-direction: row;
justify-content: space-around;
width: calc( 100% - 50% );
}

.eachContainer{
  display: flex;   
  flex-direction: row;
   gap: 1rem !important;
   /* align-items: center; */
   
}

.iconCont{
     padding-top: 0.5rem;
}

.numbertextincon{
   /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
   Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
   margin: 0;
   font-size:2rem !important;
   font-weight: bold;
}

.textinIns{
    color: #4e5566;
    font-size: 1.1rem;
    font-family: Poppins,sans-serif;

}





/* //////////////////////////successful instructor css////////////////////// //////////////////////////////////*/

.successfulInsContainer{
    background-color: #f5f7fa;    
   
}


.successfulInsideContainer{ 
   padding: 4rem 10rem 3rem 10rem;
}

.successfullInstructortext{
   font-family: Poppins,sans-serif;
   font-size: 2.3rem !important;
   font-weight: 600 !important;
}

.SuccesMainBoxContainer{
   display: flex;
   flex-direction: row;
   gap: 2rem;
   justify-content: center;
   padding: 2rem !important;
   flex-wrap: wrap;
}



.eachBoxesinSucces{
   height: 17rem;  
   width: 22rem;
   background-color:#ffffff;
   padding: 1.5rem !important;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1rem;
}
.innerBox{
   
   width: 5rem;
   height: 5rem;
   display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
 
}

.boxInsturctortext{
 
   font-size: 1.3rem !important;
   /* font-family: Poppins,sans-serif !important; */
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
     font-weight: 600 !important;
    
}

.boxInsturctordes{
   font-family: Poppins,sans-serif;
   font-weight: 0.9rem !important;
   color: #4e5566 !important;
   margin: 0 !important;
   padding:0rem 1rem !important ;
}






/*////////////////////// How It works css//////////////////  */

.HIWContainer{
     padding: 6rem 5rem 3rem 10rem;
}

.HIWInnerContainer{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  
}

.leftHIW{
  width: 50%;   
}

.rightHIW{
   margin-top: 2rem;
   width: calc( 100% - 50%  );
}
.rightHIWImageCon{
   width: 100%;  
   height: auto; 

}

.rightHIWImage{
 width: 100%;
 height: 100%;
 background-size: cover;
}

.HIWtext{
   font-size: 3.8rem;
   font-family: Poppins,sans-serif ;
   font-weight: 600;
}

.desContainer{
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
   
}  

.desContainersecond{
 display: flex;
 flex-direction: row;
 gap: 1.5rem;
 align-items: center;
}

.numbers{
 color: #d9dee6; 
 font-size: 5.5rem;
 font-family: Poppins,sans-serif;
 /* font-weight: 600; */

} 

.textandiconContainer{
   display: flex;  
   flex-direction: row;
   align-items: center;
   padding:1.5rem;
   gap: 1rem;
   border: 1px solid #dbd9d9;
   border-radius: 1.3rem;
   width: 70%;
   box-shadow: 5px 5px 20px 10px #feeeee;
}


.iconContainer{
   width: 60px !important;
   height:  60px !important;
    
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

}
.headinginHIw{
   font-size: 1.6rem;  
   font-family: Poppins,sans-serif;
   font-weight: 600;
   color: #1e242c;
}

.desHiw{
     
 font-family: Poppins,sans-serif;  
 font-size: 1.1rem;
 color: #414d60;
 
 /* background-color: red; */
 
}





/*//////////////// Don t worry we re always
here to help you     csss//////////////////////////////////// */

.dontWContainer{
 background-color: #ffeee8; 
   padding:5rem 5rem 0rem 5rem;
   
}

.dontWInsideContainer{
   
   display: flex;
   flex-direction: row;
   align-items: center;


}

.leftdWC{
   width: 50%; 
   /* background-color: red; */
   

}
.rightDWC{
   width: calc( 100% - 50% );  
   
}

.girlimageContaier{

   width: 100%;
   height: auto;
   /* background-color: green; */
   /* width: 650px;
   height: 650px; */
}

.girlImage{
   
   width: 100%;
   height: 100%;
   object-fit: cover;

}



.DWtextheading{
     
      font-size: 2.5rem;
      color:#2e3035 ;
      font-family: Poppins,sans-serif;
      font-weight: 600;
}
.DWdesc{
    color: #8d8e9a;
    font-size: 1.1rem;
}

.iconandTextCont{

    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #1d2026;
    font-family: Poppins,sans-serif;
}

.emailBoxCont{
   width: 50px;
   height: 50px;
   border-radius: 50%;
   background-color: #ffffff;
   display: flex;
   align-items: center;
   justify-content: center;
}

.anytimeAnywhertext{
      
   color: #8d8e9a; 
   margin: 0;  
   font-size: 1rem;
   font-family: Poppins,sans-serif;

}

.emailtext{
    font-family: Poppins,sans-serif;
    font-weight: 600;
}



/*//////////////////////////////////////////  Instructor success story csss/////////////////////////////     */

.InsSuccessContainer{
    
   padding: 5rem 15rem 0rem 15rem ;
   display: flex;
   flex-direction: row;
    gap: 10rem;
    align-items: center;

    
}

.successLeftPart{

   width: 40%;
   font-family: Poppins,sans-serif !important;
}

.successRightPart{
    /* background-color: gray; */
    width: calc( 100% - 40% );
    display: flex;
    flex-direction: column;
    gap: 2rem;

}

.bottomFirstImageCon{
     display: flex;
     flex-direction: row;
     gap: 2rem;
}

.firstImageCon{
   width: 255px;
   height: 255px;
}
.firstImage{
   width: 100%;
   height: 100%;
   object-fit: cover;
}







.quoteContainer{
    padding: 1.5rem;
    background-color: #ffeee8;
}

.successHeadingtext{
 font-size: 2rem;
 font-weight: 600;
}

.succesDes{
   color: #8d8e9a;
   font-size: 1.1rem;
}

.quotetext{
    padding-top: 1rem;
    font-size: 1.1rem;
    color: #2e3035;
}

.arrowBtns{
   padding-top: 2rem;
   display: flex;
   align-items: center;
   gap: 1rem;
}

.arrobtn{

  padding: 0.8rem 0.7rem;
  cursor: pointer;
}


.arrowleft{
     background-color: #f5f7fa; 
}

.arroright{
  background-color:#ff6636 ;
  color: #ffffff;
}

.arrowleft:hover{
   background-color: #caccce; 
}

.arroright:hover{
   background-color:#fd8560 ;  
}




@media screen  and (max-width:1700px) { 

   .upperSectionTutor{
         padding: 0 2rem;
   }

   .bAnIntext{
       font-size: 2.6rem !important;
   }
   .instDescription{
       font-size:1.3rem !important ;
   }

 .sCICLSRContainer{
   padding: 3rem 5rem;
 }

 .successfulInsideContainer{
   padding: 4rem 2rem 3rem 2rem !important;
 }
 .SuccesMainBoxContainer{
   padding-inline: 0 !important;
 }

 .HIWContainer{
    padding: 6rem 2rem 3rem 5rem;
 } 

 .HIWtext{
   font-size: 3.5rem;
 }

 .headinginHIw{
   font-size:1.4rem ;
 }
 .desContainer{
   gap: 1rem;
 }
 .desContainersecond{
   gap: 1rem;
 }

 .numbers{
   font-size: 5.2rem;
 }

 .dontWContainer{
   padding: 5rem 2rem 0rem 0rem ;
 }

 .InsSuccessContainer{
   padding: 5rem 8rem 0rem 5rem;
 }


   
}

@media screen and (max-width:1250px) { 

    .upperSectionTutor{
       flex-direction: column-reverse;
       /* width: 100%; */
       gap: 1rem;
       padding-bottom: 2rem;
       align-items: center;
    }

    .leftInstuctorpart{
       width: 100% !important;
    }
    .rightInstuctorPart{
       width: 90% !important;
    }

  

   .firstTwoCon{
       flex-direction: column;
       /* background-color: red; */
       width: 100%;
   }

   .lastTwoCon{
       flex-direction: column;
       /* background-color: green; */
       width: 100%;
   }

   .HIWInnerContainer{

       flex-direction: column-reverse;
   }

  .leftHIW{
       width: 100%;
  }
  .rightHIW{
        width: 90%;
        margin-top: 0rem;
  }

 .dontWInsideContainer{

    flex-direction: column;
 }

 .leftdWC{
   width: 100%;
 }
 .rightDWC{
   width: 90%;
   margin-top: -3rem;
 }
  

 .InsSuccessContainer{
   flex-direction: column;
 
 }

 .successLeftPart{
    width: 100%;
 }

 .successRightPart{
       width: 100%;
    
 }
 .bottomFirstImageCon{
   align-self: center !important;
 }

 .firstImageCon{
    width: 230px;
    height: 230px;
 }





    
   
}


@media screen  and (max-width:650px) {

   .upperSectionTutor{
       padding: 0 0rem;
   }   

   .rightInstuctorPart{
       width: 100% !important;
    }

   .leftInstuctorpart{
       margin-top: -3rem;
    } 

    .bAnIntext{
       font-size:1.8rem !important; 
    }
    .instDescription{
       font-size: 1.2rem !important;
    }

    .tutorProfilebtn{
       font-size: 1.3rem;
       margin-bottom: 2rem;
    }

    .sCICLSRContainer{
       padding: 3rem 2rem;
    }
    .numbertextincon{
       font-size: 1.7rem !important;
    }

    .textinIns{
       font-size: 1rem;
    }

    .successfullInstructortext{
       font-size: 2rem !important;
    }

    .eachBoxesinSucces{
       height: 18rem;
       width: 22rem;
    }

    .HIWContainer{
       padding:6rem 2rem 3rem 3rem ;
       margin-bottom: 3rem;

    }

    .rightHIW{
       width: 100%;
    }
    .leftHIW{
     margin-top: -3rem;
    }

    .HIWtext{
       font-size:2.6rem !important ;
    }

    .numbers{
       font-size: 3rem;
    }

    .textandiconContainer{
       flex-direction: column;
       width: 100%;
       padding: 1rem;
    }

    .headinginHIw{
       font-size: 1.2rem !important;
    }
    .desHiw{ 
        font-size: 1rem;
     }

     .DWtextheading{
       font-size: 2rem;
     }

     .InsSuccessContainer{
       padding: 5rem 2rem 0rem 2rem ;
     }

     .successHeadingtext{
       font-size: 1.5rem;
     }

     .succesDes{
       font-size: 1rem;
     }

     .quotetext{
       font-size: 1rem;
     }
     .arrobtn{
       padding:0.6rem 0.6rem  ;
     }


}

@media screen and (max-width:576px) {
   .bottomFirstImageCon{
       flex-direction: column;
   }
   .successRightPart{
       margin-top: -3rem;
   }

   .desContainer{
     flex-direction: column;
   }
   .desContainersecond{
     flex-direction: column-reverse;
   }
}