.timelinecontainer {
  padding-top: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  height: 100%;
  width: 100%;
  /* background-color: red; */
  /* position: relative; */
  /* text-align: center; */
  
}

.hourupcomoming {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.viewallinlesson {
  color: #2f80ed;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
  
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;

}

.verticaltimeline ul {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 0 !important;
  position: relative;
}

.verticaltimeline ul li {
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  list-style: none;
  margin: 0;
  padding: 0 !important;
  /* background-color: green; */
}

.time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 1rem;
  margin-top: -0.32rem;
  /* background-color: green; */
}

.start-time,
.end-time {
  color: #a3afca;
  font-size: 1rem;
}

.vertical-line {
  width: 1.5px;
  background-color: #7c7c7e;
  /* flex-grow: 1; */
  height: 5.82rem;
}

.alldetails {
  display: flex;
  flex-direction: row;

  border-radius: 1.12rem;
  padding: 0.31rem;
  gap: 0.5rem;
  min-height: 6.87;
  max-height: 7rem;
  width: 100%;
  margin-top: 0.5rem;
}

.iconcon {
  background-color: #ff5333;
  height: 1.75rem;
  width: 1.75rem;
  text-align: center;
  border-radius: 42%;
  flex-shrink: 0;
}

.title {
  color: black;
  font-family: Poppins, sans-serif;
  font-size: 0.9rem;
  margin: 0;
  /* word-break: normal; */
}

.timerange {
  color: #8c8f97;
  font-family: "Poppins,sans-serif";
}

.teachername {
  font-size: 0.78rem;
  margin-left: 0.3rem;
  margin-top: 0.18rem;
  font-family: Poppins, sans-serif;
  color: black;
}

.typelesson {
  color: #8c8f97;
  margin-left: 0.8rem;
  font-size: 0.93rem;
  /* font-family: "Poppins,sans-serif"; */
}

.verticaltimeline ul li::before {
  content: "";
  position: absolute;
  top: 8.75rem;
  left: 0.93rem;

  border: 1px solid #7c7c7e;
  height: 3.43rem;
}

.btnContainer{
  width: 100%;
  /* background-color: green; */
  text-align: center;
  margin-top: 2rem;
}

.baLesson {
  background-color: #ed5c33;
  color: white;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 1.5rem;
  cursor: pointer !important;
  display: inline-flex;
   margin-left: 0;
  font-family: Poppins, sans-serif;
  white-space: nowrap;
  /* align-self: center; */
  /* margin: auto !important; */
  /* text-align: center; */
}

.baLesson:hover {
  background-color: #f67457 !important;
}

.noclassToday {
  /* background-color: #ed5c33;  */
  text-align: center;
  margin-top: 5rem;
  font-size: 2rem;
  font-family: "Poppins,sans-serif";
  text-shadow: 2px 5px 5px #ed5c33;
  position: relative;
}

@media screen and (max-width: 415px) {
  .baLesson {
    padding: 0.5rem 1rem;
  
  }
  .alldetails {
    max-height: 8rem;
  }
}

@media screen and (max-width: 360px) {
  .teachername {
    font-size: 0.7rem;
    margin-left: 0rem;
  }
  .typelesson {
    margin-left: 0;
  }
}
