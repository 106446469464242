.pills,.table{
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
}
.pills::-webkit-scrollbar,.table::-webkit-scrollbar{
    display: none !important;
    width:0 !important;
    background: transparent;
}
.scroll{
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
}
.scroll::-webkit-scrollbar{
    display: none !important;
    width:0 !important;
    background: transparent;
}
.tab{
    background-color:rgb(41,45,54);
    border-radius:20px
}
.expired{
    color:gray !important
}
.btn_outline{
    background-color: white ;
    color:rgb(98,204,107);
    border:2px solid rgb(98,204,107);
}  
.btn_outline:hover{
    background-color: rgb(98,204,107);
    color:white
}
.red{
    color:red !important;
    border-color:red !important;
    font-family:'Baloo'
}
.course_image{
    width:95%;
    padding-bottom: 45%;
    height:0;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
}
.pp{
    width:40%;
    height:0;
    padding-bottom: 40%;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}
.tab {
    background-color: rgb(41,45,54);
    border-radius:20px;
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important; 
}
.tab::-webkit-scrollbar{
    display: none !important;
    width:0 !important;
    background: transparent;
}
.heading {
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important; 
}
.heading::-webkit-scrollbar{
    display: none !important;
    width:0 !important;
    background: transparent;
}
.studentSubPP{
    border-radius:20px;
    width:100%;
    height:0;
    padding-bottom: 100%;
    background-size:cover;
    background-image:url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/background.jpg')
}
.ReviewPP{
    height:6rem;
    width:6rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}
.review{
    width:60%;
    height:0;
    padding-bottom:60%;
    border-radius: 50%;
    background-size:cover;
    background-position:center
}
@media screen and (max-width:768px){
    .pills{
        width:98% !important;
    }
    
}