.gohome {
  display: flex;
  align-items: center;
  padding:1rem 3rem 1rem 6rem
}

.backicon:hover {
  color: #2f80ed !important ;
  text-decoration: underline !important;
}
.overviewcontainer {
  padding-bottom: 5rem;
}

.mainbgcontainer {
  /* background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/CourseHeroBg.png"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  }

  .loadingWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 15rem;
   }

.courseTeacher{
  
   align-self: center;
   display: flex;
   justify-content: center;
}

.imgdesc {
  display: flex;
  flex-direction: row;
  padding: 2rem 4rem 3rem 6rem;
  gap: 2rem;
}
.textcontainer {
  margin-top: 2rem;
  width: 45%;
}

.imageContainer {
  width: 35%;
  height:0;
  padding-bottom:35%;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  /* max-height: 30rem; */
}

.imageicon {
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/CourseHero.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.imge {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.advanctext {
  font-size: clamp(33px, 4vw, 50px);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.lowertext {
  font-size: clamp(33px, 4vw, 50px);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.description {
  /* color: #4d4d4d; */
  font-size: clamp(16px, 4vw, 20px);
  font-size: Poppins, sans-serif;
}
.trialbtn {
  background-color: #fd661f;
  color: white;
  padding: 5px 20px;
}

.trialbtn:hover {
  color: white !important;
}

.scrollarrow {
  background: none;
  border: none;
  color: #f5690c;

  font-size: clamp(25px, 4vw, 35px);
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.leftarrow {
  left: 0;
  /* left: 20px;  */
}

.rightarrow {
  right: 3%;
  /* right: 20px;  */
}

.coursescontainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 3rem 1.25rem;
}

.courseslistwrapper {
  overflow-x: auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;

  display: flex;
}

.courseslist {
  width: 100%;
  display: flex;
  gap: 5rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px;
  scrollbar-width: none;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}

.courseslist::-webkit-scrollbar {
  display: none;
}

.coursebtn {
  color: #636263;
  display: flex;
  font-weight: 700;
  font-size: clamp(1.2rem, 4vw, 1.3rem);
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.5s ease, color 0.5s ease, border 0.5s ease;
  position: relative;

  justify-content: center;
  align-items: center;
  font-family: Poppins,sans-serif !important;
}

.activeclass {
  color: #1d2026;
}

.activeclass::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleX(1.6);
  bottom: -10px;
  height: 3px;
  background-color: #ff6636;
  width: 100%;
  transition: transform 0.5s ease;
  z-index: 1;
}

.courseslist::-webkit-scrollbar {
  display: none;
}

.bottomoverview {
  /* background-color: red !important ; */
  width: 100%;
}

/* darkactive */

.darkactive {
  color: white;
}
.darkactive::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleX(1.6);
  bottom: -10px;
  height: 3px;
  background-color: #ff6636;
  width: 100%;
  transition: transform 0.5s ease;
  z-index: 1;
}


.loadingFaq{
   width: 100%;  
   text-align: center;
   margin-top: 5rem;
}

.noFaqs{
   font-size: clamp(1.1rem ,3vw,1.3rem);
   color: #007bff;
   font-family: Poppins,sans-serif;
}


.faqcontainer {
  width: 78%;
}

.freaq {
  width: 85%;

  padding: 1rem 1rem;
  margin: auto;
}

.addaunit {
  background-color: #ff6636;
  color: #ffffff;
  padding: 0.7rem 1.4rem;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
  margin: 1rem 0rem;
  border-radius: 23px;
}
.addaunit:hover {
  background-color: #fb7756;
}

.viewreviews {
  background-color: #ff6636;
  color: #ffffff;
  padding: 0.9rem 1.4rem;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 1.3rem;
  text-align: center;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
  border-radius: 23px;
  margin-top: -1rem;
}

.viewreviews:hover {
  background-color: #fb7756;
}

/* .accordion-header,.accordion-button{
    background-color: red;
} */

.addToCartBtn{
  font-family: Poppins,sans-serif;
  font-size: 1.5rem;
  position: fixed;
  background-color: #0d6efd;
  z-index: 100;
  bottom: 1.875rem;
  right: 1.875rem;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1.3rem;
  border-radius: 2rem;
  text-align: center;

}  

.addToCartBtn:hover{ 
  background-color: #3a86f8;
}

.addToCartImageCon{
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  

} 

.addTocartImage{
  width: 90%;
  height: 90%;
  object-fit: cover;
  position: relative;
 
}


.addFaqbtn {
  font-size: 1.7rem;
  background-color: #c5e8fe;
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  margin-left: 10rem;
  margin-bottom: 2rem;
}

.addFaqbtn:hover {
  background-color: #d1e5f2;
  color: #3a3e47;
}

.alreadyAddedFaqs{
  font-size: 1.2rem;
  background-color: #c5e8fe;
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  margin-left: 10rem;
  margin-bottom: 2rem; 
}


.commontbtn {
  padding: 0.6rem 1.3rem;
  cursor: pointer;
  font-size: 1.1rem;
  text-align: center;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
  border-radius: 5px;
}



.cancel {
  background-color: rgb(182, 178, 178);
  color: black;
  font-weight: 600;
}
.cancel:hover {
  background-color: rgb(216, 201, 201);
}
.submit {
  background-color: #ff6636;
  color: white;
  font-weight: 600;
}
.submit:hover {
  background-color: #f76d43;
}





@media screen and (max-width: 1024px) {
  .imgdesc {
    flex-direction: column-reverse;
    gap: 0rem;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  .gohome {
    padding:1rem 3rem 1rem 3rem
  }
  .textcontainer {
    /* background-color: red; */
    width: 80%;
    /* margin-top: -1rem; */
  }

  .imageContainer {
    width: 35%;
    height:0;
    padding-bottom:35%;
    border-radius: 50%;
    background-size: cover;
    /* max-height: 30rem; */
  }

  .coursescontainer {
    padding: 3rem 2rem;
  }

  .courseslistwrapper {
    padding: 0.5rem 2rem;
  }

  .courseslist {
    justify-content: flex-start;
    gap: 2.5rem;
    padding: 0.625rem 0rem;
  }

  .faqcontainer {
    width: 96%;
  }
  .freaq {
    width: 95%;
  }

  .addFaqbtn{
     margin-left: 5rem;
  }
  .alreadyAddedFaqs{
    margin-left: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .viewreviews,
  .addaunit {
    padding: 0.5rem 0.9rem;
    font-size: 1.1rem;
  }
  .courseTeacher{
    padding-right: 1rem;
  }
  .addFaqbtn{
    margin-left: 2rem;
    margin-bottom: 3rem;
    font-size: 1.3rem;
 }
 .alreadyAddedFaqs{
   margin-left: 0.5rem;
   margin-bottom: 3rem;
   
 }
}

@media screen and (min-width: 810px) and (max-width: 1024px) {
  .courseslistwrapper {
    margin-left: 5rem;
  }
}