/* tutor section css   */
.gohome {
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  margin: 1.5rem 0rem 0rem 0rem;
}

.backicon:hover {
  color: #2f80ed !important ;
  text-decoration: underline !important;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.tutorcont {
  display: flex;
  flex-direction: row;
  width: 85%;
  gap: 3.5rem;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  /* background-color: red; */
}

.imagcontainer {
  width: 180px;
  height: 180px;
  flex-shrink: 0;
}

.text {
  color: #81878b;
  font-weight: 600;
  /* font-size: 1.12rem; */
  font-size: clamp(0.8rem, 4vw, 1.12rem);
  /* font-family: Poppins,sans-serif; */
}
.truncated {
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
  line-clamp: 4 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

/* css for nav tabs  */

.coursescontainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 1.25rem;
}

.courseslistwrapper {
  overflow-x: auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  /* background-color: red; */
}

.courseslist {
  width: 100%;
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px;
  scrollbar-width: none;
  white-space: nowrap;
  align-items: center;
  /* justify-content: flex-start; may be i have to add media query for this for mobile screen     */
  justify-content: space-evenly;
}

.coursebtn {
  color: #717072;
  display: flex;
  font-weight: 700;
  font-size: clamp(1rem, 4vw, 1.3rem);
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.5s ease, color 0.5s ease, border 0.5s ease;
  position: relative;

  justify-content: center;
  align-items: center;
}

.activeclass {
  color: #1d2026;
}

.activeclass::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleX(1.8);
  bottom: -10px;
  height: 3px;
  background-color: #ff6636;
  width: 100%;
  transition: transform 0.5s ease;
  z-index: 1;
}

.courseslist::-webkit-scrollbar {
  display: none;
}

.scrollarrow {
  background: none;
  border: none;
  color: #f5690c;

  font-size: clamp(25px, 4vw, 35px);
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.leftarrow {
  left: 0;
  /* left: 20px;  */
}

.rightarrow {
  right: 0;
  /* right: 20px;  */
}

.resume {
  width: 100%;
}

.timedurationbox {
  font-size: clamp(0.9rem, 3vw, 1.2rem);
  border: 2px solid #686868;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  flex-shrink: 0;
  cursor: pointer;
}
.timedurationbox:hover {
  background-color: #ccc;
}

.schedule_container {
  position: relative;
  margin-top: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  justify-content: space-between;
  gap: 1rem;
}

.DWMcontainer {
  display: inline-flex;
  width:auto !important;
  flex-direction: row;
  font-family: Poppins, sans-serif;
  gap: 1.5rem;
  align-items: center;
  justify-content: flex-start;
  border-radius: 1.4rem;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  margin-top: 1rem;
}

.TWMonth {
  margin: 0 !important;
  padding: 2px 12px;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: background-color, ease 0.8s;
}

.homeSchedule {
  margin-top: 3rem;
  padding: 0 3rem;
}

.infomessage {
  font-family: Poppins, sans-serif;
  color: rgb(247, 49, 49);
  margin-top: 1rem;
  padding-left: 2rem;
  text-decoration: underline !important;
}

.requestAclass {
  margin-top: 1rem;
  display: block;
  background-color: #ed5c33;
  color: white;
  border: none;
  padding: 0.5rem 2rem;
 font-weight: 600;
  border-radius: 1.5rem;
  cursor: pointer;
  width:auto !important;
}
.requestAclass:hover {
  background-color: #f67457;
}


.commontbtn {
  padding: 0.6rem 1.3rem;
  cursor: pointer;
  font-size: 1.1rem;
  text-align: center;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
  border-radius: 5px;
}

.cancel {
  background-color: rgb(182, 178, 178);
  color: black;
  font-weight: 600;
}
.cancel:hover {
  background-color: rgb(216, 201, 201);
}
.submit {
  background-color: #ff6636;
  color: white;
  font-weight: 600;
}
.submit:hover {
  background-color: #ef3c05;
}

/* media query css   */

@media screen and (max-width: 576px) {
  .tutorcont {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    width: 100%;
  }

  .imagcontainer {
    width: 150px;
    height: 150px;
  }

  .homeSchedule {
    margin-top: 3rem;
    padding: 0 2rem;
  }
}

@media screen and (min-width: 576px) {
  .tutorcont {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    width: 100%;
  }

  .imagcontainer {
    width: 180px;
    height: 180px;
  }
}

@media screen and (min-width: 768px) {
  .tutorcont {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
  .imagcontainer {
    width: 200px;
    height: 200px;
  }

  .resume {
    width: 70%;
  }

  /* .activeclass::after {
    content: '';
    position: absolute;
    left: 50%; 
    transform: translateX(-50%) scaleX(2); 
    bottom: -10px; 
    height: 3px;
    background-color: #ff6636; 
    width: 100%; 
    transition: transform 0.5s ease;
    z-index: 1;
  } */
}

@media screen and (min-width: 992px) {
  .tutorcont {
    display: flex;
    flex-direction: row;
    width: 85%;
    gap: 3.5rem;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
  }
  .imagcontainer {
    width: 260px;
    height: 260px;
  }
}
