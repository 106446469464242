
.ql-container {
    height: 12.5rem   !important;
    border: 2px solid #e9eaf0 !important;
  
  }
  
  .ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
   
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}

/* Define placeholder colors */
.light-theme .ql-editor::before {
    color: #8c94a3 !important ; 
    font-size: 1.1rem !important;
    font-weight: 500 !important;
  }
  
  .dark-theme .ql-editor::before {
    color: #ffffff !important ; 
    font-size: 1.1rem !important;
    font-weight: 500 !important;
  }
  
  /* Define editor text colors */
  .light-theme .ql-editor {
    color: #8c94a3 !important;
    font-size: 1.1rem;

  }
  
  .dark-theme .ql-editor {
    color: #ffffff;
    font-size: 1.1rem;
  }


      
  
  /* Position the toolbar below the text area */
  #toolbar {
   
    border-bottom: 2px solid #e9eaf0 ;
    border-left:2px solid #e9eaf0 ;
    border-right: 2px solid #e9eaf0;
    border-top: 0 !important;
    background-color: transparent !important;
    padding: 0.8rem;
    display: flex;

  }
  
  #toolbar .ql-formats {
    display: inline-block;
    margin-right: 10px;
   
    display: flex;
    align-items: center ;
    gap: 0.4rem;
}  
.ql-container.ql-snow {
    border-bottom: none; 
  }


/* Increase toolbar button size */
#toolbar .ql-formats .ql-button {
  /* padding: 10px; */
}

#toolbar .ql-formats button {
  font-size: 1.3rem !important; /* Increase button size */
  /* padding: 8px;  */
}




/* Add background color for active/selected toolbar buttons */
#toolbar .ql-formats .ql-active,
#toolbar .ql-formats button:hover {
  background-color: #cdc9c9; /* Change to desired background color */
}

/* Dark and Light theme styling */
.dark-theme #toolbar {
  background-color: #333;
  color: #ffffff;

}

.light-theme #toolbar {
  background-color: #f5f5f5;
}
  
@media screen and (max-width:576px) {
    .ql-container{
      height: 10rem !important;
    }

    #toolbar .ql-formats {
      
      gap: 0.1rem;

      margin-right: 0px;

    }

    #toolbar .ql-formats button {
      font-size: 1rem !important; 
    
    }
}

@media screen and (max-width:400px) { 
  #toolbar .ql-formats {
      
    gap: 0rem !important;
  margin-right: 0px;

  }

  #toolbar{
    padding: 0.2rem;
  }
  .ql-container{
    height: 9rem !important;
  }
  
}