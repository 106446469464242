.aboutUsContainer {
  padding: 5rem 15rem 5rem 15rem;
}

.upperContainer {
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.leftUpper {
  width: 50%;
  font-family: Poppins, sans-serif;
  /* background-color: yellow; */
}

.rightUpper {
  width: calc(100% - 50%);
}

.boxforimages {
  background-color: #f2f2f2;
  /* width: 55%; */
 /* height: 130%; */
     width: 23rem;
     height: 25rem;
    

  border-radius: 0.7rem;
  position: relative;
}

.firstImageCont {
  position: absolute;
  width: 74%;
  top: -5%;
  right: -5%;
}

.secondImageCont {
  position: absolute;
  width: 74%;

  top: calc(100% - 42%);

  left: -5%;
}

.firstImage {
  width: 100%;
  border-radius: 0.7rem;
  height: auto;
  object-fit: contain;
}
.secondImage {
  width: 100%;
  border-radius: 0.7rem;
  height: auto;
  object-fit: contain;
}



.aboutusText {
  color: #9530da;
  font-size: 2.2rem;
  margin: 0;
  font-weight: 600;
}
.belowTextdes {
  color: #202020;
  font-size: clamp(1.3rem, 3vw, 1.6rem);
  font-weight: 600;
}

.descriptionText {
  font-size: clamp(1rem, 3vw, 1.1rem);
  color: #727272;
}

.joinUsbtn {
  background-color: #fe9b31;
  color: #ffffff;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;

  font-size: 1.1rem;
  border-radius: 1.3rem;
  text-align: center;
}

.middleContainer {
  margin-top: 10rem;
  display: flex;
  flex-direction: row;
}

.lefteMiddle {
  width: 50%;
}

.rightMiddle {
  width: calc(100% - 50%);
  margin-top: 3rem;
  padding-right: 5rem;
  font-family: Poppins, sans-serif;
}

.girlImageContainer {
  width: 78%;
  margin-top: -1rem;
}
.homePageContainer {
  width: 100%;
  margin-top: -1rem;
  display: flex;
  justify-content: center;
}

.girlImageinAbout {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-position: center;
}
.homePageinAbout {
  width: 78%;
  object-fit: contain;
  background-position: center;
}
.middletext {
  color: #202020;
  font-size: clamp(1.3rem, 3vw, 1.6rem);
  font-weight: 600;
}

.bottomContainer {
  font-family: Poppins, sans-serif;
}

.ourbenefittext {
  color: #db9c2b;
  font-size: 1.2rem;

  font-weight: bold;
  text-align: center;
  margin: 0;
}
.bottomtitletext {
  text-align: center;
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  font-weight: bold;
  /* line-height: 2.7rem; */
}

.smallbelowtext {
  text-align: center;
  color: #9a9a9a;
  font-size: clamp(0.9rem, 3vw, 1rem);
}

.textnumberinabout {
  color: #e3a15a !important;
  font-size: 2rem !important;
  font-weight: bold !important;

}
.infoTitletext {
  color: #202020 !important;
  font-size: clamp(1.2rem, 3vw, 1.3rem) !important;
  font-weight: 600 !important;
  font-family: Poppins,sans-serif !important;
}

.infoDescription {
  font-size: 1.1rem !important ;
  color: #727272 !important;
  font-family: Poppins,sans-serif !important;
}


@media screen and (max-width: 1700px) {
     
    .aboutUsContainer {
        padding: 5rem 8rem 5rem 8rem;
      }

      .bottomContainer{
        margin-top: 2rem;
      }

  }

  @media screen  and ( max-width:1350px)  {
         .aboutUsContainer{
              padding: 5rem 4rem 5rem 4rem;
         }
         
         .girlImageContainer {
            width: 90%;
        
          }
          .homePageContainer {
            width: 90%;
        
          }

          .rightMiddle{
            padding-right: 1rem;
          }
  }


   
  @media screen  and (max-width:1024px) {
      
    
    .upperContainer{
         flex-direction: column;
         /* background-color: red; */
         align-items: center;
         justify-content: center;
    }

     .leftUpper{
        width: 95%;
     }
     .rightUpper {
        width: 70%;
      }

      .middleContainer{
        flex-direction: column-reverse;
        align-items: center ;
        justify-content: center;
        gap: 2rem;
      }

      .lefteMiddle{
         width: 95%;
      }

      .rightMiddle{
           width: 95%;
           margin-top: -3rem;
      }
  }


  @media screen and (max-width:576px) {
    .boxforimages {
          
        width: 16rem;
        height: 16rem;
    }
    .aboutUsContainer{
        padding: 5rem 1.5rem 5rem 1.5rem;
   }
   .lefteMiddle{
    width: 100%;
    margin-top: 2rem;
 }  

 .bottomContainer{
  margin-top: -2rem;
 }
 
  }




















/* previous code  */

/* .black{
    background-color: black;
    background-image:url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/black_cover.svg');
    background-size: cover;
    background-position: 15%;
}
.story{
    background-color: rgb(244,248,254);
}
.heading{
    color:white
}
.demo_img,.future_img{
    height:0;
    width:100%;
    padding-bottom: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.demo_img{
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/scholaristgraphics1.svg');
}
.future_img{
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/future.svg');
}
.teacher_img{
    border-radius: 50%;
    height:0;
    width:70%;
    padding-bottom: 70%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */
