.studentContainer {
  /* background-color: red;       */

  padding: 2rem 10rem;
}

.studentinsidecon {
  /* display: flex;
        align-items: center;
        justify-content: center; */
}

.sloadingWrapper {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 2rem;
}
.notanyStudent {
  font-size: clamp("1.2rem ,3vw,1.5rem");
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.searchBoxmainCon {
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.searchboxContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.studentText {
  font-size: 2.3rem;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.WtoLearn {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 29rem;
  border: 1px solid;
  border-radius: 0.2rem;
  padding: 0.7rem 0.8rem;
  position: relative;
}

.closebtn {
  position: absolute;
  left: 93%;
}

/* Light theme placeholder */
.lightPlaceholder::placeholder {
  color: #8c94a3;
}

/* Dark theme placeholder */
.darkPlaceholder::placeholder {
  color: white;
}

.darkDropdown {
  background-color: #394249;
}

.searchBarResults {
  display: flex;
  /* flex-direction: column; */
  margin-top: 0.32px;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.8rem;
  font-family: Poppins, sans-serif;
  cursor: pointer;
}
.searchBarResults:hover {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

.searchDropdown {
  position: absolute;
  z-index: 1;
  top: 2.5rem;
  width: 98% !important;
  height: 10rem;
  overflow-y: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 0.32rem;
}
.lightdropDown {
  background-color: white !important;
  border: 1px solid #d4d6d6 !important ;
  box-shadow: -10px 10px 8px rgba(192, 191, 191, 0.2),
    10px 10px 8px rgba(192, 191, 191, 0.2);
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none;
}

.tableStyles {
  color: #dfe7f0;
  width: 100%;
  border-collapse: collapse;
  min-width: 700px;
  /* Ensure the table doesn't shrink too small */
  /* background-color: green; */
}

.tableContainer {
  overflow-x: auto;
  overflow-y: hidden;
}

.tableContainer::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.tableContainer::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: rgba(152, 149, 149, 0.267);
}

.WheaderStyles {
  border: none !important;
  padding: 0.6rem 1rem 0.6rem 1rem !important  ;
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem !important;
}

.WcellStyles {
  border-bottom: none !important;
  font-family: Poppins, sans-serif;
  font-size: 1.3rem;
  padding: 1rem 1rem !important;
  vertical-align: middle !important;
  white-space: nowrap;
}
.darkWcellStyles {
  color: #ffffff;
}
.whiteWcellStyles {
  color: #505768;
}

.textwithdraw {
  font-size: 1.3rem !important;
  font-family: Poppins, sans-serif;
}

.viewProfile {
  background-color: #7f71d8;
  color: white;
  font-size: 1.1rem;
  font-family: Poppins, sans-serif;
  padding: 0.4rem 0.9rem;
  border-radius: 1.8rem;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.WcellStylesImage {
  border-bottom: none !important;
  font-family: Poppins, sans-serif;
  font-size: 1.3rem;
  padding: 1rem 1rem !important;
  vertical-align: middle !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem !important;
  white-space: nowrap;
}

.whiteWcellStylesImage {
  color: #191b1c;
}

.imageCon {
  width: 35px !important;
  height: 35px !important;
  display: block;
}
.singleImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;
}

@media screen and (max-width: 1200px) {
  .studentContainer {
    padding: 2rem 5rem;
  }
}

@media screen and (max-width: 800px) {
  .studentContainer {
    padding: 2rem 2.5rem;
  }

  .pagination {
    flex-direction: column;
  }

  .textwithdraw {
    align-self: first baseline;
  }

  .pagenationbtn {
    align-self: last baseline;
  }

  .studentText {
    font-size: 1.5rem;
    padding-left: 0rem;
  }

  .searchBoxmainCon {
    padding-left: 0rem;
  }

  .searchboxContainer {
    gap: 0.5rem;
  }

  .WtoLearn {
    width: 24rem !important;
  }
}

@media screen and (max-width: 576px) {
  .searchboxContainer {
    flex-direction: column;
  }

  /* .studentText{
                  align-self: first baseline;
                } */

  .WtoLearn {
    padding-right: 1rem;
  }
  .searchBoxmainCon {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .WtoLearn {
    width: 20rem !important;
  }
}
