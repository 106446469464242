.maincontainer {
  width: 100%;
}

.innercontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.leftoptions {
  width: 14%;
}

.notificationPart {
  width: 87%;
  padding: 2.5rem 3rem 3rem 3rem;
  min-height: 100vh;
  
  

}

.sidbartogglebtn {
  font-family: Poppins, sans-serif;
  display:inline-flex ;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 1.1rem;
}

.textsCon {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
}

.filterheader {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
}

.firstTowBoxes {
  display: flex;
  flex-direction: row;
  width: calc(95% - 30%);
}

.lastTwoboxes {
  width: 30%;
  display: flex;
  flex-direction: row;
}

.filterNotification {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #dcdddf;
  border-radius: 0.6rem;
  background-color: #f7f8fa;
  padding: 0.6rem 0.5rem 0.6rem 0.5rem !important;
  margin-left: 2rem;
  width: 80%;
  margin-right: 2rem;
}

.filtertextInput {
  outline: none;
  border: none;
  background-color: #f7f8fa;
  font-family: Poppins, sans-serif;
  width: 100%;
}
.filtertextInput::placeholder {
  color: #adaeb1;
}

.dataPicker {
  width: 50% !important;
  margin-right: 2rem;
  border-radius: 0.8rem !important;
}

.daysSelector {
  width: 50% !important;
}

.allUnreadCon {
  font-family: Poppins, sans-serif;
  display: inline-flex;
  /* border: 1px solid #dcdddf; */
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  font-size: 1.1rem;
}

.allText {
  margin: 0;
  padding: 0.7rem 1.5rem 0.7rem 1rem;
  background-color: #eeeff2;
  color: black;
  border-radius: 0.6rem 0rem 0rem 0.6rem;
  cursor: pointer;
  overflow: hidden;
  border-top: 1px solid #dcdddf;
  border-left: 1px solid #dcdddf;
  border-bottom: 1px solid #dcdddf;
}

.unreadText {
  margin: 0;
  padding: 0.7rem 2rem 0.7rem 2rem;
  background-color: #f7f8fa;
  color: black;
  border-radius: 0rem 0.6rem 0.6rem 0rem;
  cursor: pointer;
  overflow: hidden;
  border-top: 1px solid #dcdddf;
  border-right: 1px solid #dcdddf;
  border-bottom: 1px solid #dcdddf;
}

.allNotificationCon {
  padding-top: 3rem;
  padding-right: 15rem;

  max-height: 80vh; 
  overflow: auto;
}

.LoadingWrapper{
     margin-top: 5rem;
}
.noNotification{
   margin-top: 3rem ;
   font-weight: 600;
   text-align: center;
   font-size: clamp(1.3rem ,3vw,1.1rem);
   
}

.notificationMessage {
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.notificationDate {
  margin-top: 0.5rem;
  font-family: Poppins, sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
}

.singleNotification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  border-bottom: 2px solid #dddfdf;
  padding-bottom: 2rem;
  

}

.imageAndText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.7rem;
    margin-right: 3rem;

}

.logoCon {
  width: 6.5rem;
  height: 6.5rem;
  /* border-radius: 50%; */
  flex-shrink: 0;
}

.logo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
}

.rightimageCon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.rightImmage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prevNextCon {
  display: flex;
  justify-content: end !important;
  margin-bottom: 5rem;
}

.paginationBtn {
  /* background-color: red;  */
  border-radius: 1rem;
}

.singlebtnFir {
  padding: 0.4rem 1.2rem;
  color: #90add3;
  background-color: #f7f8fa;
  border: 1px solid #dcdddf;
  font-family: Poppins, sans-serif;
  border-radius: 0.8rem 0 0 0.8rem;
}

.singlebtnSec {
  padding: 0.4rem 1.2rem;
  color: #90add3;
  background-color: #f7f8fa;
  border: 1px solid #dcdddf;
  font-family: Poppins, sans-serif;
  border-radius: 0 0.8rem 0.8rem 0rem;
}



@media screen and (max-width: 1600px) {
  .leftoptions {
    width: 20%;
  }
}

@media screen and (max-width: 1500px) {
  .leftoptions {
    display: none;
  }
  .notificationPart {
    width: 100%;
    padding: 1.5rem 0rem 0 1.2rem;
  }
}

@media screen and (max-width: 1150px) {
  .filterheader {
    flex-direction: column;
    gap: 2rem;
  }
  .firstTowBoxes {
    width: 100%;
  }

  .lastTwoboxes {
    width: 100%;
    align-self: last baseline !important;
    /* background-color: red; */
  }

  .dataPicker {
    width: 45% !important;
  }

  .daysSelector {
    width: 45% !important;
  }

  .filterNotification {
    width: 71%;
  }

  .allNotificationCon {
    padding-right: 8rem;
  }
}

@media screen and (max-width: 776px) {
  .singleNotification {
    flex-direction: column;
  }
}

@media screen and (max-width: 576px) {
  .textsCon {
    gap: 1rem;
  }

  .allText {
    padding: 0.6rem 1.5rem 0.6rem 1rem;
  }

  .unreadText {
    padding: 0.6rem 1.5rem 0.6rem 1rem;
  }

  .filterNotification {
    padding: 0.6rem 0.5rem 0.6rem 0.5rem;
    width: 80%;
    margin-left: 0rem;
  }

  .firstTowBoxes {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  .lastTwoboxes {
    flex-direction: column;
    gap: 1rem;
  }

  .dataPicker {
    width: 90% !important;
  }

  .daysSelector {
    width: 90% !important;
  }

  
  .allNotificationCon {
    padding-right: 3rem;
  }

  .logoCon {
    width: 6rem;
    height: 6rem;
  }


  .imageAndText{
     flex-direction: column;
     margin-right: 0rem;
  }

}

@media screen and (min-width: 1500px) {
  .sidbartogglebtn {
    display: none;
  }
}

