
body {
  overflow-x:clip;
  overscroll-behavior-x: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  
      /* font-family:  "Poppins, sans-serif"; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

#slider::-webkit-scrollbar {
  display: none !important;
  width:0 !important;
}
#tslider::-webkit-scrollbar {
  display: none !important;
  width:0 !important;
}
div.bg-green::-webkit-scrollbar{
  display: none !important;
  width:0 !important;
}
/* Hide scrollbar for IE, Edge and Firefox */
#slider{
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
#tslider{
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-35{
  width:auto;
}
a.nav-link{
  color:white !important;
  text-decoration: none;
}
.w-adjust{
  width:75%;
}
.no-decor{
  text-decoration: none !important;
  color: inherit !important;
}
.min-height-100{
  height:60vh;
}
 

/* #pagecontent::after {
  content:'';
  color:'white' ;
  position: absolute ;
  top: 40% ;
  transform: translateY(40%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  pointer-events: all;
  right: 3%;
  background-image: url(./assets/images/next.png);
} */
.w_90{
  width:90%;
}
.w_10{
  width:10%;
}

/* Hide default scrollbar */
#pagecontent::-webkit-scrollbar {
  display: none;
}
/* @media screen and (min-width:576px){
  
  
  #pagecontent::after {
    right: 6.5%;
  }
} */
.terms {
  background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/black_cover.svg');
  background-size:cover;
  background-repeat: no-repeat;
  background-position: 15%;
}
.aichatbot{
}



@media screen and (min-width:992px){
  .w-35{
    width:35%;
  }
  .w-adjust{
    width:100%;
  }
  .aichatbot{
    top:24% !important;
    left:38% !important;
  }
}
@media screen and (min-width:1200px){
  .aichatbot{
    top:24% !important;
    left:65% !important
  }
}
@media screen and (min-width:1500px){
  .aichatbot{
    top:24% !important;
    left:95% !important
  }
}
@media screen and (min-width:1800px){
  .aichatbot{

    top:24% !important;
    left:130% !important
  }
}
@media screen and (min-width:2100px){
  .aichatbot{
    top:24% !important;
    left:150% !important
  }
}
@media screen and (min-height:1050px){
  .min-height-100{
    height:70vh
  }
}
@media screen and (min-height:1500px){
  .min-height-100{
    height:75vh
  }
}
@media screen and (min-height:1800px){
  .min-height-100{
    height:80vh
  }
}