.studentDemoContainer {
    padding: 6rem 7rem 15rem 7rem;
  }
  
  .firstContainer {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  
  .firstLeft {
    width: 60%;
  }
  
  .firstRight {
    width: calc(100% - 60%);
  }
  
  .firstImageCont {
    width: 85%;
    height: auto;
  }
  
  .firstImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .howitworksText {
    font-family: Poppins, sans-serif;
    font-size: 6rem;
    color: #161616;
    font-weight: 500;
  }
  
  .numberAndTexts {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
  }
  
  .iconTextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    border: 3px solid #f1efef !important;
    padding: 1.1rem 1.5rem !important;
    border-radius: 1.5rem;
    box-shadow: 0px 0px 30px 15px #fef3f3;
  }
  
  .numertext {
    color: #d9dde6;
    font-size: 8rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
  }
  
  .iconContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: #e1e9fe;
    border-radius: 50%;
    padding: 0.8rem;
  }
  
  .onlyTexts {
    display: flex;
    flex-direction: column;
    font-family: Poppins, sans-serif;
  }
  
  .firstText {
    margin: 0;
    font-size: 2.2rem;
    color: #1e242c;
    font-weight: 500;
  }
  
  .secondText {
    font-size: 1.5rem;
    color: #455163;
  }
  
  .secondContainer {
    display: flex;
    flex-direction: row;
  
    gap: 3rem;
    align-items: center;
  }
  
  .secondLeft {
    width: 40%;
  }
  .secondRight {
    width: calc(100% - 40%);
  }
  
  .SeconConfirstText {
    margin: 0;
    font-size: 2rem;
    color: #1e242c;
    font-weight: 500;
  }
  
  .SecondConsecondText {
    font-size: 1.2rem;
    color: #455163;
  }
  
  .ThirdContainer {
    padding-top: 5rem;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: center;
  }
  
  .thirdLeft {
    width: 55%;
  }
  
  .thirdRight {
    width: calc(100% - 55%);
  }
  
  .thirdImageContiner {
    width: 100%;
    height: auto;
  }
  
  .thirdImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .FourthContainer {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: center;
  }
  
  .fourthLeft {
    width: 45%;
    margin-top: -5rem;
  }
  
  .fourthRight {
    /* margin-top: -15rem; */
    width: calc(100% - 45%);
  }
  
  .foutthImageContainer {
    width: 100%;
    height: auto;
  }
  
  .fourthImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .fifthContainer {
    padding-top: 3rem;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: center;
  }
  
  .fifthLeft {
    width: 50%;
  }
  
  .fifththRight {
    width: calc(100% - 50%);
  }
  
  .fifthImageContainer {
    width: 100%;
    height: auto;
  }
  
  .fitthImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .sixthContainer {
    padding-top: 4rem;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: center;
  }
  
  .sixthLeft {
    width: 50%;
  }
  
  .sixthRight {
    width: calc(100% - 50%);
  }
  
  .sixthImageContainer {
    width: 100%;
    height: auto;
  }
  
  .sixthImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media screen and (max-width: 1650px) {
    .studentDemoContainer {
      padding: 6rem 3.5rem 15rem 3.5rem;
    }
  
    .firstImageCont {
      width: 100%;
      height: auto;
    }
  
    .howitworksText {
      font-size: 5rem;
    }
  
    .numertext {
      font-size: 6rem;
    }
  
    .firstText {
      font-size: 2rem;
    }
  
    .secondText {
      font-size: 1.3rem;
    }
  
    .SeconConfirstText {
      font-size: 1.8rem;
    }
    .SecondConsecondText {
      font-size: 1.1rem;
    }
  }
  
  @media screen and (max-width: 1350px) {
    .firstContainer {
      flex-direction: column;
      gap: 4rem;
      align-items: center;
      justify-content: center;
    }
    .firstLeft {
      width: 100%;
      align-self: center;
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: -2rem;
    }
  
    .firstRight {
      width: 100%;
  
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .firstImageCont {
      width: 80%;
    }
  
    .secondContainer {
      flex-direction: column-reverse;
    }
  
    .secondLeft {
      width: 100%;
      align-self: center;
    }
  
    .secondRight {
      width: 90%;
    }
  
    .ThirdContainer {
      flex-direction: column;
    }
  
    .thirdLeft {
      width: 100%;
  
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .thirdRight {
      width: 90%;
    }
  
    .FourthContainer {
      flex-direction: column-reverse;
      margin-top: 5rem;
    }
  
    .fourthLeft {
      width: 100%;
      margin-top: 3rem;
    }
  
    .fourthRight {
      width: 95%;
    }
  
    .fifthContainer {
      flex-direction: column;
      margin-top: 4rem;
    }
  
    .fifthLeft {
      width: 90%;
    }
  
    .fifththRight {
      width: 100%;
    }
  
    .sixthContainer {
      flex-direction: column-reverse;
      gap: 5rem;
    }
  
    .sixthLeft {
      width: 100%;
    }
  
    .sixthRight {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 820px) {
    .studentDemoContainer {
      padding: 6rem 1.5rem 15rem 1.5rem;
    }
  
    .howitworksText {
      font-size: 3.5rem;
    }
  
    .numertext {
      font-size: 4rem;
    }
  
    .firstText {
      font-size: 1.5rem;
    }
  
    .firstImageCont {
      width: 95%;
    }
  
    .secondText {
      font-size: 1.1rem;
    }
  
    .secondRight {
      width: 100%;
    }
  
    .SeconConfirstText {
      font-size: 1.5rem;
    }
  
    .SecondConsecondText {
      font-size: 1rem;
    }
  
    .thirdRight {
      width: 100%;
    }
  
    .fourthRight {
      width: 100%;
    }
  
    .fifthLeft {
      width: 100%;
    }
  
    .sixthRight {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 550px) {
    .howitworksText {
      font-size: 3rem;
    }
  
    .iconTextContainer {
      flex-direction: column-reverse;
      padding: 1.1rem 1rem !important;
    }
  
    .numberAndTexts {
      flex-direction: column;
      gap: 0rem;
    }
  }