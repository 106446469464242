.bookingContainer{
    margin-top: 3rem;
     margin-bottom: 10rem;
}

.heading{
    font-size:clamp(1rem,3vw,1.5rem);
    font-weight: 700;
    font-family: Poppins,sans-serif;
    text-decoration: underline;

}

.avatarimg {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
  }

  .view {
    background-color: #cbe6f0 !important ;
    color: #4e7584 !important ;
    padding: 0.2rem 1rem;
    cursor: pointer;
    outline: none;
    border: none;
  }

  .grad {
    font-family: Poppins, sans-serif;
    background-color: #f1d4bc;
    color: #d69a8c;
    padding: 0.2rem 1rem;

    /* border-radius: 0.3rem; */
    cursor: pointer;
    outline: none;
    border: none;
  }