.react-calendar {
  background-color: transparent !important ;
  /* padding-top: 1rem !important; */
  border: none !important ;
  width: 100% !important ;

  /* font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important; */
  font-family: Poppins, sans-serif !important ;
  font-size: larger !important ;
}

.react-calendar__navigation {
  display: flex !important  ;
  justify-content: space-between !important ;
  align-items: center !important ;
}

.react-calendar__tile {
  text-align: center;
  border-radius: 50%;
  width: 48px !important;
  height: 48px !important;
  font-size: 1.2rem !important;
}

.react-calendar__tile--now {
  background: none !important ;
  color: #dfe7f0 !important ;
}

.react-calendar__tile--active {
  
  background: #2196f3 !important ;
  color: white !important ;
 
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #4c4c4c !important ;
  color: white !important ;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  background-color: #117dc9 !important ;

  padding: 0.2rem 0.6rem;
  border-radius: 1.5rem !important ;
  color: white !important;
}
.react-calendar__month-view__weekdays {
  text-transform: capitalize !important ;
  /* cursor: pointer !important; */
}
.react-calendar__month-view__weekdays abbr {
  text-decoration: none !important;
  border: none !important;
  cursor: pointer !important;
  font-size: 1rem !important;

  /* for background color of weekdays */
  /* background-color: #373e48; 
 padding: 0.3rem 0.4rem;
border-radius: 9px; */
}

.custom-arrow-left,
.custom-arrow-right {
  font-size: 1.5rem; /* Adjust the size of the arrows */
  /* color: white; */
  cursor: pointer;
  margin-left: 0.5rem;
}

.custom-arrow-left:hover,
.custom-arrow-right:hover {
  background-color: transparent !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important; /* No background color on hover */
  color: white !important; /* Keep text color white */
}

.light-weekdays .react-calendar__month-view__weekdays {
  color: black !important; /* Black color for weekdays in light theme */
}

/* Styles for light theme */
.light-theme .react-calendar__tile {
  color: black !important; /* Default black for dates in light theme */
}

.light-theme .weekend-red {
  color: red !important; /* Red color for Sundays and Saturdays in light theme */
}
.weekend-red abbr {
  color: red !important;
}

/* Styles for dark theme */
.dark-theme .react-calendar__tile {
  color: white !important; /* Default white for dates in dark theme */
}
/* by this all number color changes */
.dark-theme abbr {
  color: white;
}

.light-theme .notweekend abbr {
  color: black !important;
}

.dark-theme .notweekend abbr {
  color: white !important;
}

@media screen and (max-width: 500px) {
  .react-calendar__tile {
    font-size: 1rem !important;
  }

  .react-calendar__month-view__weekdays abbr {
    font-size: 0.8rem !important;
  }
  .react-calendar {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 330px) {
  .react-calendar {
    font-size: 0.8rem !important;
  }
}
