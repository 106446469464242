

.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  }


.maincontainer {
  margin: 1rem 0rem;
  padding: 1rem 0rem;
 
}

.insideContainer {
  padding: 0.5rem 0rem;

  display: flex;
  flex-direction: row;
}

.checkboxContainer {

  width: 17%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.CorsesorTeacherContainer {

  width: 83%;
}

.Goback{
   display: inline-flex;
   align-items: center;
   gap: 0.5rem;
   cursor: pointer;
   padding-left: 1rem;
   font-family: Poppins,sans-serif;
}



.toggleBtn {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.coursebtn {
  background: white;
  color: #717072;
  border: 0.12rem solid #c3c2c4;
  padding: 0.18rem 1.18rem;
  border-radius: 0.62rem;
  display: flex;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
  align-items: center;
  justify-content: center;
}

.activeclass {
  background-color: #9e35cc;
  color: white;
  border: none;
}

.coursecontent {
  margin: 1.5rem auto;
  /* margin: 2rem auto 3rem auto !important; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  /* background-color: red; */
  height: 100%;
  position: relative;
  top: 15%;
  left: 45%;
}

.fiterbtn {
  background: white;
  color: #717072;
  border: 0.12rem solid #c3c2c4;
  padding: 0.18rem 1.18rem;
  border-radius: 0.62rem;
  display: flex;
  cursor: pointer;
  gap: 0.5rem;
  font-family: Poppins, sans-serif;
  /* transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease; */
  align-items: center;
  justify-content: center;
}

/*//////////////////// checkboxes css  /////////////////////*/

.languagefilter {
  width: 95%;
  border: 1px solid #b8b3b3;
  font-family: Poppins, sans-serif;
  border-radius: 0px 2px 0px 0px;
  /* background-color: red; */
  margin-top: 5px;
}

.filterheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  border-bottom: 1px solid #ccc;
  border-radius: 0px 2px 0px 0px;
  padding: 5px 15px 5px 15px;
}

.darkfiterheader {
  background-color: #394249;
  color: white;
}

.lightfilterheader {
  background-color: #f9f9f9;
  color: black;
}

.languagelist {
  list-style: none;
  margin: 0;
  padding: 0;
}
.languagelist li {
  padding-left: 1.23rem;
}

.languageitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
}
.darklanguageitme {
  border: 1px solid #394249;
}
.lightlanguageitme {
  border-bottom: 1px solid #ddd8d8;
}

.languageitem label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.languagecount {
  color: #999;
  padding-right: 0.2rem;
}

.languages {
  font-size: 0.875rem;
}

/* Hide default checkbox appearance */
.languages input[type="checkbox"] {
  appearance: none;
  margin-right: 0.625rem;
  width: 1.12rem;
  height: 1.12rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

/* When the checkbox is checked, change the background to orange */
.languages input[type="checkbox"]:checked {
  background-color: #f16d33;
  border-color: #f16d33;
  position: relative;
}

/* checmark for checked language box */
.languages input[type="checkbox"]:checked::after {
  content: "✔";
  position: absolute;
  top: -1px;
  left: 3px;
  font-size: 0.9rem;
  color: white;
}

/* media query css */

@media screen and (max-width: 1590px) {
  .checkboxContainer {
    display: none;
  }
  .CorsesorTeacherContainer {
    width: 100%;
    padding: 0 2rem 0 3rem;
  }
  .languagefilter {
    width: 100%;
  }
  .Goback{
    padding-top: 1rem !important;
  }
}

@media screen and (max-width: 500px) {
  .insideContainer {
    padding: 0;
  }
  .CorsesorTeacherContainer {
    padding: 0 0 0 2rem;
  }

  .toggleBtn {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    scrollbar-width: none;
    
  }


}

@media screen and (min-width: 1590px) {
  .fiterbtn {
    display: none;
  }
  .MobCheckbox{
    display: none;
  }
}
