.paymentMainContainer {
  padding-top: 4rem;
  padding-bottom: 5rem;
}

.checkoutBox {
  background-color: #f5f7fa;
}

.checkoutText {
  text-align: center !important;
  font-size: 2rem !important;
  color: #1d2026;
  font-weight: 500 !important;
  padding: 2rem 0rem;
  font-family: Poppins, sans-serif;
}

.paymentMethodContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4rem;
  padding: 2rem 13rem;
}

.paymentText{
      font-family: Poppins,sans-serif;
      font-size: 2rem;
      font-weight: 600;
      
}


.coursesDetails {
  width: 70%;
}

.courseDetailInsideCont {
  border: 3px solid #e9eaf0 !important;
}

.eachItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.3rem;
}

.allListContainer {
  border-bottom: 2px solid #e9eaf0;
  padding: 2rem;
}

.coursesLeftPart {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.courseHeadingText {
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.courseImgCon {
  width: 90px;
  height: 70px;
}

.courseImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.courbyText {
  margin: 0;
  font-family: Poppins, sans-serif;
  white-space: nowrap;
}
.courseByTexsFist {
  color: #a1a5b3;
  white-space: nowrap;
}

.courseName {
  margin: 0;
  font-family: Poppins, sans-serif;
  white-space: nowrap;
  font-size: 1.1rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;

}

.priceText {
  margin: 0;
  color: #ff6636;
  font-weight: 600;
  font-size: 1.1rem;
}

.qtyRightPart {
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  white-space: nowrap;
  cursor: pointer;
}

.orderSummery {
  padding: 2rem 0rem 0rem 2rem;
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
}

.subTotal {
  padding: 0rem 2rem 0rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.subtotalText {
  font-size: 1.1rem;
  color: #8d8e9a;
}
.subtotlNumber {
  
  font-size: 1.1rem;
  font-weight: 600;
}

.couponDis {
  padding: 0rem 2rem 0rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #e9eaf0;

  font-family: Poppins, sans-serif;
}

.coupondisText {
  font-size: 1.1rem;
  color: #8d8e9a;
}
.percetDisText {
  color: #1d2026;
  font-size: 1.1rem;
  font-weight: 600;
}

.oldPriceText {
  text-decoration: line-through;
  color: #a1a5b3;
  padding-left: 0.5rem;
}

.totalCon {
  padding: 0.5rem 2rem 0rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.totalText {
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
}

.totalPriceText {
  font-weight: 700;
  font-size: 1.8rem !important;
  color: #1d2026;
}

.paymentBtnCon {
  padding: 0.8rem 2rem 0.5rem 2rem;
}

.paymentBtn {
  text-align: center;
  background-color: #ff6636;
  color: #ffffff;
  padding: 0.7rem 0rem;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  cursor:pointer
}

.emptyCart{
   font-family: Poppins,sans-serif; 
   font-size: 2rem;
   font-weight: 500;
}

.gohome {
  display: flex;
  align-items: center;
  padding:2rem 5rem 1rem 5rem
}

.backicon:hover {
  color: #2f80ed !important ;
  text-decoration: underline !important;
}

@media screen and (min-width:1601px){
  .coursesDetails{
    width:'50%'
  }
}

@media screen and (max-width:1600px) {
      
    .paymentMethodContainer{
          padding: 2rem 5rem;
    } 

}

@media screen and (max-width:1350px) {
      
    .paymentMethodContainer{
          flex-direction: column-reverse;
          align-items: center;
    }

  .paymentMethodDetails{
       width: 90%;
    }

.coursesDetails{
     width: 90%;
}

}


@media screen and (max-width:776px) {
   
    .paymentMethodContainer{
        padding: 2rem 3rem;
  } 
   
    .paymentMethodDetails{
           width: 100%;
    }

    .coursesDetails{
           width: 100%;
    }
}


@media screen and (max-width:576px) {
    
    .paymentMethodContainer{
        padding: 2rem 2rem;
  } 
    .eachItem{
        flex-direction: column;
        gap: 1rem;
   }
    
   .courseByTexsFist{
    white-space: wrap;
   }

   .courseName{
    white-space: wrap;
   }

}