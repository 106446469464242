.maincontainer {
  /* background-color: red; */
  padding: 0rem 5rem;

  /* i will remove this later */
  margin-bottom: 5rem;
}

.insidecontainer {
  /* background-color: green; */
  padding: 2rem 4rem;
}

.loadingContainer {
  text-align: center;
  margin-top: 5rem;
}

.noAssingnments{
  margin-top: 2rem;
  font-size: clamp(1.1rem ,3vw,1.4rem);
  color: #564ffd;
  font-family: Poppins,sans-serif;
}


.addAssbtn {
  font-size: 1.7rem;
  background-color: #c5e8fe;
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  /* margin-left: 10rem; */
  margin-bottom: 2rem;
}

.addAssbtn :hover {
  background-color: #d1e5f2;
  color: #3a3e47;
}

/* assignment css  */
.assignment {
  overflow-x: auto;
  margin: 0rem auto;
  width: 80%;
  padding-bottom: 5rem;
  /* background-color: red; */
}
.assignment::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.assignment::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: rgba(152, 149, 149, 0.267);
}

.tableStyles {
  color: #dfe7f0;
  width: 100%;
  border-collapse: collapse;
  min-width: 700px; /* Ensure the table doesn't shrink too small */
}

.darktable tbody tr:nth-child(odd) {
  background-color: #353535;
}
.lighttable tbody tr:nth-child(odd) {
  background-color: #f5f6f8;
}

.headerStyles {
  border: none;
  padding: 0 1.1rem 1.1rem 1.1rem !important  ;
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  white-space: nowrap;
}

.cellStyles {
  border-bottom: none;
  white-space: nowrap;
  font-family: Poppins, sans-serif;
  font-size: 0.99rem;
  padding: 0.8rem 1.1rem !important ;
  vertical-align: middle !important;
}
.cessStatus {
  border-bottom: none;

  font-family: Poppins, sans-serif;
  font-size: 0.88rem;
  vertical-align: middle !important;
  border-radius: 4px !important;
  display: inline-block;
  padding: 0.1rem 0.3rem;
  text-align: center !important;
  margin-top: 0.6rem;
}

.completed {
  background-color: #d9eed1 !important ;
  color: #72ad5c !important ;
}
.view {
  background-color: #cbe6f0 !important ;
   
  color: #4e7584 !important ;
  padding: 0.2rem 1rem;
  cursor: pointer;
}
.activeAssignment{
  background-color:#6bccef!important ;
}

.arrupdown {
  font-size: 1.2rem;
}

.popovertext {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  align-self: flex-start;
  color: #667073;
  cursor: pointer;
}
.popoverdelete {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  align-self: flex-start;
  color: #f0310b;
  cursor: pointer;
}

/* bottome css */
.asstext {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
}

.viewreviews {
  background-color: #ff6636;
  color: #ffffff;
  padding: 0.6rem 1rem;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 0.9rem;
  text-align: center;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
  border-radius: 13px;
}

.viewreviews:hover {
  background-color: #fb7756;
}

.heading {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
}

.avatarimg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
}

.status {
  font-family: Poppins, sans-serif;
  display: flex;
  align-items: center;

  justify-content: center;

  padding: 0.1rem 0.4rem 0.1rem 0.4rem;
  border-radius: 1rem;
  font-size: 0.9rem;
  /* gap: 0rem; */
}
.dot,
svg {
  margin-right: 0.2rem;
}

.view {
  font-family: Poppins, sans-serif;
  background-color: #c7e7ef;
  color: #577681;
  padding: 0.2rem 0.9rem 0.2rem 0.9rem;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  cursor: pointer;
  outline: none;
  border: none;
}

.grad {
  font-family: Poppins, sans-serif;
  background-color: #f1d4bc;
  color: #d69a8c;
  padding: 0.2rem 0.9rem 0.2rem 0.9rem;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  cursor: pointer;
  outline: none;
  border: none;
}

.commontbtn {
  padding: 0.6rem 1.3rem;
  cursor: pointer;
  font-size: 0.9rem;
  text-align: center;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
  border-radius: 5px;
}

.cancle {
  background-color: rbga(255, 255, 255, 0.3);
  color: black;
  font-weight: 600;
}
.cancle:hover {
  background-color: rgb(182, 178, 178);
}
.submit {
  background-color: #fd754c;
  color: white;
  font-weight: 600;
}
.submit:hover {
  background-color: #f95827;
}

@media screen and (max-width: 1150px) {
  .insidecontainer {
    padding: 2 1.5rem;
  }
}

@media screen and (max-width: 991px) {
  .maincontainer {
    padding: 2rem 0rem;
  }
}

@media screen and (max-width: 576px) {
  .insidecontainer {
    /* padding: 2rem 1rem; */
    padding: 2rem 1rem 2rem 2rem;
  }

  .addAssbtn {
    font-size: 1.2rem;
    margin-bottom: 3rem;
  }
}
