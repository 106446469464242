.container{
    border-radius: 20px;
    background-color: rgb(41,45,54);
}
.howtouse{
    background-image:url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/black_cover.svg);
    background-size: cover;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: 15%;
    min-height: 85vh;
}
.border{
    border-color: white;
    border-radius: 20px;
}
.studentPic{
    width:40%;
    height:0;
    padding-bottom: 40%;
    border-radius: 50%;
    background-image:url(https://scholaristdemo.s3.us-east-2.amazonaws.com/Student_icon.png);
    background-size: cover;
    background-position: center;
}
.teacherPic{
    width:40%;
    height:0;
    padding-bottom: 40%;
    border-radius: 50%;
    background-image:url(https://scholaristdemo.s3.us-east-2.amazonaws.com/teacher_icon.png);
    background-size: cover;
    background-position: center;
}
.walking_student{
    width:25%;
    height:0;
    padding-bottom: 80%;
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/Group.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position:relative;
    left:-15%;
}
.walking_teacher{
    width:25%;
    height:0;
    padding-bottom: 80%;
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/teacher.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position:relative;
    left:-15%;
}
.signUpForm{
    width:65%; 
}
.signUpForm{
    height:0;
    padding-bottom: 80%;
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/SignUp.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.sidebar,.tsidebar{
    width:25%;
    height:0;
    padding-bottom:70%;
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/Sidebar.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

}
.tsidebar{
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/TSidebar.png);
}
.remaining{
    width:65%;
    height:60%;
    flex-grow:1;
}
.Patricia,.Mariana,.Henry{
    width:100%;
    height:0;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
}
.Patricia{
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/PatriciaOnScholarist.png);
}
.Mariana {
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/MarianaOnScholarist.png);
}
.Henry{
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/Henru.png);
}
.course{
    width:100%;
    height:0;
    padding-bottom:70%;
    background-size: cover;
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/courses.png);
}
.create,.main{
    width:80%;
    height:0;
    padding-bottom:50%;
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/Create.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.main{
    
    background-image: url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/Lecture.png);
    
}
@media screen and (max-width:576px){
    .walking_student{
        transform: scale(1.2);
    }
    .signUpForm{
        transform: scale(1.4);
    }
    .sidebar,.tsidebar {
        transform:scale(1.5) translateY(2rem)
    }
    .container_slide2{
        transform: scale(1.4);
    }
    .Patricia,.Mariana,.Henry{
        transform: scale(1.2);
    }
  
}