.profilePic {
    width:100%;
    height:0;
    padding-bottom: 100%;
    border-radius: 50%;
    transform: scale(1.2);
    background-position: center;
    background-size: cover;
}
.list_container {
    flex: 1 1 auto;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none; 
    -ms-overflow-style: none;
    padding: 0.3rem 1rem 0.3rem  0.5rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.list_container::-webkit-scrollbar { 
    height: 0;
}
.list_container div div:hover{
    background-color: #ebf2ff;
    border-radius: 1.5rem;
}
.inputDiv{
    width:90%;
    height:2rem;
    position: relative;
}
.highlighted{
    /* background-color: rgb(113, 187, 126); */
    background-color: #ebf2ff;
    border-radius: 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

input:focus {
    box-shadow: none !important;
    outline: none !important;
}

.ICPContainer{
    display: flex;
    /* padding: 0.3rem 1rem 0.3rem 0.5rem; */
    /* justify-content: space-around; */
    align-items: center;
    /* justify-content: flex-start; */
    margin-left: 8%;
    font-family: Poppins,sans-serif;
    gap: 1.5rem;
}

.eachItem{
     font-weight: 600;
     font-size: 1.1rem;
     background-color: #f3f4f6;
     border-radius: 2rem;
     padding: 0.4rem 01rem;
     cursor: pointer;
     color: black;

}

.eachItem:hover{
    background-color: #ccced2;  
}

.activeClass{
     color: #f7706a;
}


.searchPic {
    width: 40%;
    height: 0;
    padding-bottom: 40%;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}

.groupBox{
    background-color: rgb(237,246,238);
    color:rgb(74, 150, 92)
}
.searchBarResults {
    cursor: pointer;
}
.searchBarResults:hover {
    background-color: skyblue;
}
.searchDropdown {
    position: absolute;
    z-index: 1;
    background-color: white;
    top:2.36rem;
    width: 100%;
    height:10rem;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color:lightgray;
    border-radius: 1rem;
}
.searchDropdownInvisible {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: white;
    top:2rem;
    border-top: 2px solid lightgray;
    width: 100%;
}

.closeIcon{
   position: absolute; 
   top: 0.5rem ; 
   right: 1rem;
   display: inline-block;
   z-index: 100;
   cursor: pointer;

}

.admin_list_container {
    flex: 1 1 auto;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none; 
    -ms-overflow-style: none;
}
.admin_list_container::-webkit-scrollbar { 
    height: 0;
}
.admin_list_container div:hover{

}
.admin_profile_pic {
    width:100%;
    height:0;
    padding-bottom: 100%;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}

.admin_highlighted {
    background-color: rgba(37,41,46,0.8);
}