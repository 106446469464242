.bottomMainContainer {
  /* background-color: red; */
  min-height: 100vh;

  /* padding: 4.5rem 6rem; */
   padding: 0rem 6rem 10rem 6rem;

}
.gohome {
    display: flex;
    align-items: center;
    width:'70%';
    padding:3rem 0 3rem 0rem;
  }
  
  .backicon:hover {
    color: #2f80ed !important ;
    text-decoration: underline !important;
  }
.revenueBoxes {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.upperBoxes {
  display: flex;
  flex-direction: row;
  /* gap: 10rem; */
  gap: 15%;
}
.lowerBoxes {
  display: flex;
  flex-direction: row;
  /* gap: 10rem; */
  gap: 10%;
}
.eachBox {
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}

.iconBox {
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.numberText {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 1.3rem !important;
}

.text {
  font-size: 1.1rem !important;
  font-family: Poppins, sans-serif;
  white-space: nowrap;
}



/*///////////////////////////////  Statistic  part css/////////////////////////////      */

.statistiContainer{
    display: flex;         
    flex-direction: row;
    gap: 5rem;
    padding: 8rem 0 2rem 0;
    align-items: center;
    justify-content: center;
    /* background-color: red; */

}

.statisticLeft{
 width: 60%;
     
}
.submit {
  background-color: #fd754c;
  color: white;
  font-weight: 600;
}
.submit:hover {
  background-color: #f95827;
}
.commontbtn {
  padding: 0.6rem 1.3rem;
  cursor: pointer;
  font-size: 0.9rem;
  text-align: center;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
  border-radius: 5px;
}

.selectContainer{
     display: flex;
     float: row;
     justify-content: space-between;
     border-bottom: 1px solid #e9eaf0;
     padding: 0rem 1rem  1rem 0rem;
}

.textstatistic{
      margin: 0;
      font-size: 1.2rem;
      font-family: Poppins,sans-serif;
      font-weight: 600;
}

.graphContainer{
    margin-top: 1rem;
}

.statisticiRight{ 
  width: calc(100% - 60%);
  /* background-color: red; */
}

.rightTextContainer{
    display: flex;
    flex-direction: column;

    background: linear-gradient(to right, #ffc773, #ffb442);
    align-items: center;
    border-radius: 1.2rem;

    padding: 2rem 4rem;
    width: 80%;
}  

.totaltext{
  margin: 0;
  color: #2c2c2c;
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  font-family: Poppins,sans-serif;

}

.amount{
      margin: 0;
      color: #464646;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2.5rem;
      font-weight: 600;

}

.DWbtns{
       margin: 0;
       display: flex;
       flex-direction: row;
       gap: 1rem;
       padding-top: 1.2rem;

}

.singleBtn{
    background-color: #ff8a00; 
    color: #ffffff;
    padding: 0.8rem 2.2rem;
    font-size: 1.2rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
}

.singleBtn:hover{
    background-color: #f9a444;
}




@media screen and (max-width:1650px) {

    .bottomMainContainer{
         padding: 0rem 3rem 10rem 4rem;
    }
    .lowerBoxes{
           white-space: nowrap;
           overflow-x: auto;
    }  
    .lowerBoxes::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
      }
      
      .lowerBoxes::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background-color: rgba(152, 149, 149, 0.267);
      }

      .statisticLeft{
        width: 70%;
      }
      .statisticiRight{
        width: calc(100% - 70%);
        /* padding: 0; */
      }
      .rightTextContainer{
         width: 100%;
      }
}


@media screen and (max-width:1500px) {
    .statistiContainer{
          flex-direction: column;

    } 

    .statisticLeft{
         width: 85%;
         /* background-color: red; */
    }
    .statisticiRight{
        /* background-color: red; */
        width: 100%;
        padding-left: 2.5rem;
    }

    .rightTextContainer{
        align-self: center;
        /* width: 40%;  */
        width: 27.6rem;
        white-space: nowrap !important;
    
    }
}



@media screen and (max-width:724px) {
    .bottomMainContainer{
        padding: 4.5rem 2rem 10rem 2rem;
   }

   .upperBoxes{
    white-space: nowrap;
    overflow-x: auto;
    padding-bottom: 0.7rem; 
   }

   .upperBoxes::-webkit-scrollbar {
  
    width: 0.5rem;
    height: 0.5rem;
  }
  
  .upperBoxes::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: rgba(152, 149, 149, 0.267);
  }
   
  .statisticLeft{
    width: 95%;
  }

  .DWbtns{
    flex-direction: column;
   
  }

  .rightTextContainer{
    width: 19rem !important;
  }
  .statisticiRight{
    padding-left: 0rem;
  }
  


}










/*//////////////////////////////// Previous code///////////////////////////////////// */
/* .courseSection{
    font-family: sans-serif;
    min-height: 110vh;
    height:auto;
    flex-grow: 1;
}
.logo{
    width:40%;
    height:0;
    padding-bottom: 10%;
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.w_48{
    flex-grow: 1;
    width:46%;
}
.width{
    width:100%;
}
.minHeight{
    min-height: 100vh !important;
}

.noHeight{
    height:0!important;
    
}
.img_fit{
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.line{
    width:35%;
    height:0;
    border: 0.5px solid rgba(0,0,0,0.5);
}
.submit{
    background-color: rgb(240,145,56);
    color:white;
}
.googlepill,.fbpill {
    display: flex !important;
    justify-content: center !important;
    width: 85% !important;
    padding: 1rem 2rem 1rem 2rem !important;
    border-color: black !important;
    border-width: 2.5px !important;
    font-weight: bold !important;
    color:rgba(0,0,0,0.6) !important;
    cursor: pointer;
}
.bg_fb{
    background-color: rgb(46,106,205);
    color: white !important;
}
.iconGoogle,.iconFacebook {
    vertical-align:baseline;
    display: inline-block;
    height:1.3rem;
    width:1.3rem;
    background-size:cover ;
    margin-right: 0.3rem;
    margin-top: 0.1rem;
}
.purple{
    background-color: blueviolet;
    color:white;
}
.iconFacebook{
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/fb.svg.png');
}
.scrollList{
    max-height:50vh;
    overflow-y: scroll;

}
.down{
    position:absolute;
    top:97.5%;
}
.image{
  width:100%;
  height:0;
  padding-bottom: 100%;
  border-radius: 6%;
  background-size: cover;
  background-position: center;
  background-repeat:no-repeat;
}
.iconGoogle{
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/google.jpeg');
}
.logo{
    height: 3rem;
    width: 12rem;
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/logo.svg');
}
.dropdown{
    position: relative;
    display: inline-block;
    cursor: pointer;
    color:white;
}
.dropdown_content{
    display: none;
    position: absolute;
    background-color:white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    min-width: 11rem;
    z-index: 1;
    color:black;

}
.hover_border:hover{
    border-bottom: 2px solid red;
}
.btn_white{
    background-color: white ;
    color:#dc3545;
}  
.btn_white:hover{
    background-color: #dc3545;
    color:white;
}
.dropdown_content div:hover{
    background-color: lightgray;
    border-left: 4px solid #dc3545;
}

.dropdown:hover .dropdown_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.language{
    width:auto;
}
.scrollingLine{
    height:0 !important;
    width:35%
}
@media screen and (max-width:992px) and (orientation:portrait) {
    .language{
        width:50%;
    }
}
@media screen and (max-width:992px) and (orientation:landscape) {
    .language{
        width:40%;
    }
}
@media screen and (min-width:992px){
    .width{
        width:75%;
    }
} */
