.text {
  font-family: "Baloo";
}
.bg {
  background-color: #fefefe;
  margin-top: 2rem;
}
.french,
.frenchexam {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
}
.french {
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/frenchicon.svg");
}
.frenchexam {
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/frenchexam.svg");
}
.english {
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/englishicon.svg");
}
.duolingo {
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/duolingo.png");
}
/* 
.avatarimg{
   background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/CourseHero.png") !important ;
   background-size: cover;
   background-position: center;
  background-repeat: no-repeat;
} */

/* https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/CourseHero.png */


.imageandtitledes{
   padding: 0 6.5rem;
 
}
.forpadding{
  padding-right: 5rem;
}
.courseImage {
  background-color: #fdf8ee;
  background-position: center right;
  background-size: cover;
  width: 100%;
  /* max-height: 23rem; */
}
.courseThumnail{
   width: 100%;
   height: 100%;
   background-size: cover;
   object-fit: cover;
}
.trialbtn {
  background-color: #fd661f;
  color: white;
  padding: 5px 20px;
}
.duolingo,
.english {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
}
.dele,
.siele {
  width: 70%;
  height: 0;
  padding-bottom: 70%;
  background-size: contain;
  background-repeat: no-repeat;
}
.dele {
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/dele.png");
}
.siele {
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/siele.png");
}
.trialbtn:hover {
  color: white !important;
}


.advanctext {
  font-size: clamp(2.0625rem, 4vw, 3.4375rem);
  font-weight: 600;
  font-family: Poppins,sans-serif !important;
  line-height: 3.5rem;
}

.description {
  color: #4d4d4d;
  font-size: clamp(16px, 4vw, 20px);

  font-family: Poppins, sans-serif !important;
}

.outercontainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.informationcont {
  box-shadow: -10px 15px 10px rgba(192, 191, 191, 0.2),
    15px 15px 10px rgba(192, 191, 191, 0.2);
  width: 80%;
}

.information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 30px;
}

.common {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 60px;
  height: 60px;
  background-color: #f4f6f9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon svg {
  font-size: clamp(20px, 3vw, 45px);
}

/* tutor section css   */

.tutorcont {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 3rem;
  justify-content: center;
  /* align-items: center; */
  padding: 0.5rem;
}

.imagcontainer {
  width: 15.625rem;
  height: 15.625rem;
  flex-shrink: 0;
}

.textcolor {
  color: #81878b;
  font-weight: 600;
  font-size: 1.1rem;
}

.btncontainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  word-wrap: nowrap !important;
}

.commonbtn {
  padding: 0.5rem 1.5rem;
  color: 2rem;
  background-color: #fd661f;
  border: none;
  border-radius: 1.43rem;
  font-size: 1.2rem;
  color: white;
  word-wrap: nowrap !important;
  font-family: Poppins, sans-serif;
}

.commonbtn:hover {
  background-color: #f8793e;
}

/*  A Roadmap success css   */

.faqcontainer {
  width: 73%;
}

.Item {
  margin-bottom: 1rem;
}
.freaq {
  width: 85%;

  padding: 1rem 1rem;
  margin: auto;
}

/* course Fees , Next Cohort Details css  */

.coursechortcontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  /* background-color: red; */
}

.coursefee {
  width: 30%;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  border: 2px solid #dedede;
}

.cadcontainer {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  justify-content: space-evenly;
  align-items: center;
}

.rightcustombox {
  border: 2px solid #dedede;
  padding: 0.5rem;
}

.chortcontainer {
  width: 70%;
}

/* media query css----------------------------------------------   */

@media screen and (max-width: 1200px) {
  .cadcontainer {
    flex-direction: column;
  } 

  .imageandtitledes{
    padding: 0 2rem;
  }
}

@media screen and (max-width: 992px) {
  /* for success rate,student and sessions */
  .informationcont {
    width: 95%;
  }
  .information {
    padding: 10px 10px;
  }

  .icon {
    width: 35px;
    height: 35px;
  }

  /* for tutor section  */

  .tutorcont {
    width: 100%;
  }

  /* faq css  */

  .faqcontainer {
    width: 90%;
  }

  .freaq {
    width: 95%;
  }

  /* css for course fee and chort */

  .coursechortcontainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -1.5rem;
    /* background-color: green; */
    width: 100vw;
  }

  .coursefee {
    /* width: 50%; */
    width: 50vw;
  }

  .cadcontainer {
    flex-direction: row;
  }
  .chortcontainer {
    /* width: 100%; */
    width: 90vw;
  }
}

@media screen and (max-width: 768px) {
  .tutorcont {
    flex-direction: column;
    align-items: center;  
    gap: 2rem;
    margin-top: 1rem;
    width: 100%;
  }
  .imagcontainer {
    width: 14rem;
    height: 14rem ;
  }

  .coursechortcontainer {
    width: 100vw;
    /* background-color: yellow; */
  }

  .coursefee {
    width: 75vw;
    word-wrap: nowrap;
  }

  .rightcustombox {
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    padding: 1rem;
  }

  .rightcustombox div {
    display: inline-block;
    min-width: 150px;
    margin-right: 1rem;
  }

  .advanctext{
    margin-top: 1rem;
  } 
  .forpadding{
     padding-right: 0rem;
  }


}

@media screen and (max-width: 576px) {
  .common {
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
  .icon {
    width: 40px;
    height: 40px;
  }
  .icon svg {
    font-size: 25px !important;
  }
  .btncontainer {
    flex-direction: column;

    align-items: center;
    align-self: center;
    gap: 0.9rem;
  }
  .commonbtn {
    padding: 0.5rem 2rem;
  }
  .tutorcont {
    align-items: center;
    justify-content: center;
  }

  .coursechortcontainer {
    width: 100vw;
  }

  .coursefee {
    width: 85vw;
  }

  .advanctext{
     margin-top: 1rem;
  }
}
