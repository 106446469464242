.bg {
  background-color: #f8d9bc;
}
.red {
  color: #dc3545;
}
.white {
  color: white;
}

.img_fit {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.logo {
  height: 6rem;
  width: 20rem;
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/scholarist_orange.png");
   /* colored log   https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/scholarist_orange.png  */
  /* wihte logo of scholarist  https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/logo.svg  */
}

.inside {
  width: 100%;
}

.allcontent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;

}

.parentOfLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow:1.5;
}

.leftmiddle ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #0b7077;
  font-size: 1.2rem;
  list-style: none;
  cursor: pointer;
  font-family: Poppins,sans-serif;
}

.leftmiddle ul a {
  text-decoration: none;
  color: #0b7077;
  
}

.right {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  font-family: Poppins,sans-serif;
  flex-grow:1;
}

.inputbutton {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 0.4rem 0.5rem;
  border-radius: 15px;
}

.emailfield {
  font-size: 15px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #0b7077;
  padding: 0.4rem 0.5rem;
  border-radius: 15px;
}
.sendbtn {
  padding: 0.4rem 0.5rem;
  background-color: #ed5c33;
  color: white;
  border-radius: 13px;
  cursor: pointer;
}

@media screen and (min-width:657px) and (max-width: 992px) {

}


@media screen and (max-width: 656px) {

  .allcontent {
    padding-top: 0.31rem;
    width:96vw;
    flex-wrap: wrap;
  }
  .parentOfLeft{
    width:90vw;
    flex:1
  }
  .right{
    flex:1
  }
  .leftmiddle ul {
    flex-direction: column; 
    font-size: 0.9rem;
    gap:8px;
  }

  .logo {
    height: 4rem;
    width: 15rem;
  }

  .inputbutton {
    padding: 5px 10px;
    gap: 10px;
  }
  .emailfield {
    font-size: 13px;
    padding: 10px 15px;
  }
  .sendbtn {
    padding: 10px 20px;
  }
}
