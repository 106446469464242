.commontbtn {
  padding: 0.6rem 1.3rem;
  cursor: pointer;
  font-size: 1.1rem;
  text-align: center;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
  border-radius: 5px;
}



.cancel {
  background-color: rgb(182, 178, 178);
  color: black;
  font-weight: 600;
}
.cancel:hover {
  background-color: rgb(216, 201, 201);
}
.submit {
  background-color: #ff6636;
  color: white;
  font-weight: 600;
}
.submit:hover {
  background-color: #f76d43;
}

.reviewbtn {
  font-size: 1.7rem;
  background-color: #c5e8fe;
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  margin-bottom: 2rem;
}

.reviewbtn:hover {
  background-color: #d1e5f2;
  color: #3a3e47;
}

.alreadyReviewed{
  display: inline-flex;
  background-color: #d1e5f2;
  border-radius: 0.5rem;
  padding: 0.5rem 0.8rem;
  font-weight: 500;
  font-family: Poppins,sans-serif;
}


@media screen and (max-width:996px) {
  .reviewbtn{
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
  }

  .alreadyReviewed{
    margin-bottom: 1rem;
  }
}