.my_high{
    margin-top: 10vh;
}
.scroll{
    scroll-behavior: smooth;
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
    flex-grow:1
}
.scroll::-webkit-scrollbar{
    display: none !important;
    width:0 !important;
    background: transparent;
}
.queue_container{
    display: flex;
    overflow-x: scroll;
}
.slideRight{
    width:40%;
    height:0;
    padding-bottom:40%;
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/home_right.svg');
    background-size: cover;
    cursor:pointer
}
.slideLeft{
    width:40%;
    height:0;
    padding-bottom:40%;
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/home_left.png');
    background-size: cover;
    cursor:pointer
}
@media screen and (max-width:768px){
    .slideRight{
        width:20%;
        height:0;
        padding-bottom:20%;
        background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/home_right.svg');
        background-size: cover;
        transform: scale(4);
    }
    .slideLeft{
        width:20%;
        height:0;
        padding-bottom:20%;
        background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/home_left.png');
        background-size: cover;
        transform:scale(4)
    }
}
