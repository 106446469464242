
.maincontainer {
  margin: 2rem 9rem;
  padding: 1rem 2rem;
}

.insideContainer {
  display: flex;
  flex-direction: column;
  /* padding: 1rem 0.5rem; */
  padding-right: 2rem;
  padding-left: -1rem;
  gap: 1.5rem;
}

.BasicSaveAndPreviewCon {
  padding: 0 4rem 0.5rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  font-family: Poppins, sans-serif;
  /* border-bottom: 2px solid #e9eaf0; */
}
.commonbtn {
  padding: 0.7rem 1.3rem;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #ff6636;
  font-weight: 600;
  white-space: nowrap;
  display: inline-flex;
}
.btnBgColor {
  background-color: #f6d8cd;
}

.btnBgColor:hover {
  background-color: #fdd3c4;
}

.titletext {
  font-size: clamp(1.5rem, 3vw, 1.6rem);
  font-weight: 600;
  white-space: nowrap;
} 


.scheduleEvent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homemyschedule {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-weight: 700;
  font-family: Poppins, sans-serif;
}

.eventavailability {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  /* background-color: rebeccapurple; */
}

.event {
  padding: 0.3rem 1rem;
  border-radius: 23px;
  font-family: Poppins, sans-serif;
  cursor: pointer;
  opacity: 1;
  transition: background-color ease 0.3s;
}

.event:hover {
  background-color: #eb5d34;
  opacity: 0.9;
}

.TimeDwmContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.3rem;
  /* background-color: red; */
  padding-right: 1.5rem;
}

.selectime {
  font-size: clamp(0.8rem, 4vw, 1rem);
  border: none;
  border-style: solid;
  border-radius: 1.5rem;
  padding: 0.3rem;
  font-family: Poppins, sans-serif;
  width: 9.5rem;
}

.DWMcontainer {
  display: flex;
  flex-direction: row;
  font-family: Poppins, sans-serif;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 1.4rem;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
}

.TWMonth {
  margin: 0 !important;
  padding: 2px 12px;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: background-color, ease 0.8s;
}

.commontbtn {
  padding: 0.6rem 1.3rem;
  cursor: pointer;
  font-size: 1.1rem;
  text-align: center;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
  border-radius: 5px;
}

.cancle {
  background-color: rgb(182, 178, 178);
  color: black;
  font-weight: 600;
}
.cancle:hover {
  background-color: rgb(216, 201, 201);
}
.submit {
  background-color: #ff6636;
  color: white;
  font-weight: 600;
}
.submit:hover {
  background-color: #f76d43;
}

.delete {
  background-color: #e70a0a;
  color: white;
  font-weight: 600;
}
.delete:hover {
  background-color: #f83c3c;
}

.update {
  background-color: #ff6636;
  color: white;
}
.update:hover {
  background-color: #ee7854;
}

.languadropdown {
  width: 48%;
}

@media screen and (max-width: 1410px) {
  .maincontainer {
    margin: 2rem 2rem;
  }
}

@media screen and (max-width: 1100px) {
  .maincontainer {
    margin: 2rem 0rem;
  }
}

@media screen and (max-width: 900px) {
  .TimeDwmContainer {
    display: flex;
    gap: 2rem;
    flex-direction: column;

  }

  .eventavailability {
    
    display: flex;
   gap: 0.8rem;
   justify-content: flex-start;
  }
  .DWMcontainer {
  
    display: flex;
    align-items: center;
    justify-content: flex-end;
 
    align-self: last baseline;
    
  }
}

.TimeDwmContainer::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.TimeDwmContainer::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: rgba(152, 149, 149, 0.267);
}

@media screen and (max-width: 600px) {
  .languadropdown {
    width: 90%;

  }
  .BasicSaveAndPreviewCon{
    flex-direction: column;
  }
  .commonbtn{
    align-self: last baseline;
  }
  .titletext{
    align-self: first baseline;
  }


}





