.label_container{
    width:90%;
}
.container {
    display: inline-block;
    background-color: blueviolet;
}
.inputDiv{
    width:90%;
    height:2rem;
    position: relative;
}
.searchPic {
    width: 90%;
    height: 0;
    padding-bottom: 90%;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}
.searchDropdown {
    position: absolute;
    z-index: 1;
    background-color: white;
    top:2.0rem;
    border-top: 2px solid lightgray;
    width: 100%;
    height:5rem;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: rgb(213,213,215);
}
.searchDropdownInvisible {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: white;
    top:2rem;
    border-top: 2px solid lightgray;
    width: 100%;
    background-color: rgb(213,213,215);
}