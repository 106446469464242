.withdrawHistoryCon {
    width: 75%;
    overflow-x: auto;
    margin-top: 5rem;
  }
  .withdrawHistoryCon::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  
  .withdrawHistoryCon::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: rgba(152, 149, 149, 0.267);
  }
    
  .tableStyles {
    color: #dfe7f0;
    width: 100%;
    border-collapse: collapse;
    min-width: 700px; /* Ensure the table doesn't shrink too small */
  }



  .WheaderStyles {
    border: none;
    padding:0.6rem 1rem 0.6rem 1rem !important  ;
    text-align:left;
    font-family:Poppins,sans-serif ;
    font-size: 0.9rem;
 }

  .WcellStyles {
    border-bottom: none;
    font-family:Poppins,sans-serif ;
    font-size: 1.1rem;
    padding: 0.8rem 1rem !important ;
    vertical-align: middle !important;
  
}
.darkWcellStyles{
color: #ffffff;
}
.whiteWcellStyles{
    color: #505768;  
}

  .pagination{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 2.5rem;
      padding: 0 2rem;
}

.textwithdraw{
    font-size: 1.2rem;
    font-family: Poppins,sans-serif;
}

.viewall{
    color:#2f80ed ;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;
    font-family: Poppins,sans-serif;
    font-size: 1.3rem;
    white-space: nowrap;
}


/*/////////////////// css of received payment/////////////////////////////// */
 
.paymentReceivedCon {
  width: 100%;
  overflow-x: auto;
  margin-top: 5rem;
  overflow-y: hidden;
}
.paymentReceivedCon::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.paymentReceivedCon::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: rgba(152, 149, 149, 0.267);
}

.payheaderStyles {
  border: none;
  padding:0.5rem 1rem 0.5rem 1rem !important;
  text-align:left;
  font-family:Poppins,sans-serif;
  font-size: 1rem;

}

.paycellStyles {
  border-bottom: none;
  font-family:Poppins,sans-serif ;
  font-size: 1.1rem;
  padding: 1rem 1rem !important ;
  vertical-align: middle !important;
  /* color: #505768; */
  white-space: nowrap;
}

.imagestyle{
    display: flex;
    padding: 1.3rem 1rem  1.2rem 1rem !important ;
}

/* .paydotStyles{
    border-bottom: none;
    font-family:Poppins,sans-serif ;
    font-size: 0.9rem;

    color: #191b1c;
} */

.dotbg{
    border-radius: 50%;
    background-color:#f5f6f7;
    text-align: center;
    /* padding: 1rem 0.6rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
}
.activedotBg{
    background-color: #005ce8;
    color: white;
}


.paytextwithdraw{
     font-size: 1.1rem;   
     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}




.boderbottom{
    margin: 1rem !important;
   border-bottom: 3px solid #e5e7e8 !important;
   padding: 1.5rem 0rem !important; 
   
}

.receivedpopovercommon{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3rem;
    color:#626c70 ;
    padding-bottom: 0.3rem;
}



@media screen  and (max-width:1100px) {
    .withdrawHistoryCon{
      width: 90%;
    }  
    
    
    .pagination{
       flex-direction: column;
       /* justify-content: last baseline; */
       /* align-items: last baseline; */
       padding: 0;
    }

    .textwithdraw{
      align-self: first baseline;
    }
    .pagenationbtn{
      align-self: last baseline;
    }
    .paytextwithdraw{
      align-self: first baseline;
    }
}