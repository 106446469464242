.truncated {
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.teacherMainContainer{
    /* background-color: green; */
    display: flex;
    align-items: center ;
    justify-content: center;
      
}