.studentProfileCon {
  padding: 5rem 15rem 8rem 15rem !important;
}

.imageandDetailsCon {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.studentImageCont {
  width: 185px;
  height: 185px;
  border-radius: 50%;
  flex-shrink: 0;
}

.studentImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.detailsContainer {
  font-family: Poppins, sans-serif;
}

.nameText {
  font-size: 1.5rem;
}

.textandIcon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
}

.languageText {
  color: #a1a5b3;
}

.statisticContainer {
  padding-top: 4rem;
  padding-left: 2rem;
}

.statisticInsideContainer {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  /* justify-content:space-between;  */
}

.headingText {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
}

.daterangeText {
  font-family: Poppins, sans-serif;

  font-size: 1.1rem;
}

.leftStatisticPart {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 3.5rem;
  width: 50%;
  /* background-color: red; */
}

.absenceandExam {
}

.rightStatisticPart {
  width: calc(100% - 50%);
}

.gradeCompleted {
  margin-top: 3.5rem;
  
  font-family: Poppins, sans-serif;
}

.iconandTextCon {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.3rem;
}
.userIcon {
  background-color: #0998f7;
  padding: 0.6rem 0.6rem;
  border-radius: 50%;
  /* text-align: center;  */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.textandNumCon {
  font-family: Poppins, sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
}

.paperIcon {
  background-color: #5aca8a;
  color: #ffffff;
  padding: 0.7rem 0.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.clockIcon {
  color: #ffffff;
  padding: 0.6rem 0.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #f9a83d;
}



.linerProAndTextCon {
  padding-bottom: 2.5rem;
}

.taskprogress {
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

.commonText {
  margin: 0;
  padding-bottom: 0.4rem;
  font-family: Poppins, sans-serif;
  font-size: 1.1rem !important;
  font-weight: 600;
}

.commonNumber {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  color: #a1a5b3;
  font-weight: 600;
}

/* table css  */

.taskStatusContainer {
  padding-top: 3rem;
}

.assignment {
  width: 100%;
  overflow-x: auto;
}
.assignment::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.assignment::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: rgba(152, 149, 149, 0.267);
}

.tableStyles {
  color: #dfe7f0;
  width: 100%;
  border-collapse: collapse;
  min-width: 800px; /* Ensure the table doesn't shrink too small */
}

.darktable tbody tr:nth-child(odd) {
  background-color: #353535;
}
.lighttable tbody tr:nth-child(odd) {
  background-color: #f5f6f8;
}

.headerStyles {
  border: none;
  padding: 0 1.1rem 1.1rem 1.1rem !important  ;
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  white-space: nowrap;
}

.cellStyles {
  border-bottom: none;
  font-family: Poppins, sans-serif;
  font-size: 0.99rem;
  padding: 0.8rem 1.1rem !important ;
  vertical-align: middle !important;
  /* background-color: red; */
  white-space: nowrap;
}

.cessStatus {
  border-bottom: none;
  font-family: Poppins, sans-serif;
  font-size: 0.88rem;
  vertical-align: middle !important;
  border-radius: 4px !important;
  display: inline-block;
  padding: 0.1rem 0.3rem;
  text-align: center !important;
  margin-top: 0.6rem;
  white-space: nowrap;
}
.completed {
  background-color: #d9eed1 !important ;
  color: #72ad5c !important ;
}
.pending {
  background-color: #cbe6f0 !important ;
  color: #4e7584 !important ;
}

@media screen and (max-width: 1600px) {
  .studentProfileCon {
    padding: 5rem 7rem 8rem 7rem !important;
  }
}

@media screen and (max-width: 1300px) {
  .statisticInsideContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
  }
  .leftStatisticPart {
    width: 80%;
  }

  .rightStatisticPart {
    width: 80%;
  }
}

@media screen and (max-width: 776px) {
  .statisticContainer {
    padding-left: 0rem;
  }

  .leftStatisticPart {
    width: 100%;
  }

  .rightStatisticPart {
    width: 100%;
  }
}

@media screen and (max-width: 676px) {
  .imageandDetailsCon {
    flex-direction: column;
  }

  .leftStatisticPart {
    flex-direction: column-reverse;
  }

  .studentProfileCon {
    padding: 5rem 3rem 8rem 3rem !important;
  }
}
