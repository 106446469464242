.img_fit {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.line {
  width: 35%;
  height: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
}
.submit {
  background-color: rgb(240, 145, 56);
  color: white;
}
.googlepill,
.fbpill,
.applepill {
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: center !important;
  width: 85% !important;
  padding: 1rem 2rem 1rem 2rem !important;
  border-color: black !important;
  border-width: 2.5px !important;
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.applepill {
  color: white;
  background-color: rgb(0, 0, 0);
}
.bg_fb {
  background-color: rgb(46, 106, 205);
  color: white !important;
}
.iconGoogle,
.iconFacebook,
.iconApple {
  vertical-align: baseline;
  display: inline-block;
  height: 1.3rem;
  width: 1.3rem;
  background-size: cover;
  margin-right: 0.3rem;
  margin-top: 0.1rem;
}
.iconFacebook {
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/fb.svg.png");
}
.iconGoogle {
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/google.jpeg");
}
.iconApple {
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/apple.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.logo {
  height: 5rem;
  width: 18rem;
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/logo.svg");
}

.hover_border:hover {
  border-bottom: 2px solid red;
}
.btn_white {
  background-color: white;
  color: #dc3545;
}
.btn_white:hover {
  background-color: #dc3545;
  color: white;
}

.language {
  width: auto;
}

.passwordvisiblity{
  cursor: pointer;
  position: absolute;
  right: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.25rem;
}

.upperbtn {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex-shrink: 0;
  gap: 5px;
}

.Navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* width: 98%; */
  width: 100%;
  margin-top: 3px;
  padding: 0 1rem 0 1rem;
}

.itemcontent {
  font-size: 19px;
}

.loginandsignup {
  display: flex;
  flex-shrink: 0;
  gap: 10px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.navbarContent {
  display: flex;
  gap: 3.125rem;
  width: 100%;
  cursor: pointer;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
   
    font-family: Poppins,sans-serif;

}

.hamburgericon {
  color: whitesmoke;
  font-size: 25px;
}
.hamburgericon,
.svg {
  font-size: 32px;
  cursor: pointer;
}

.mobilemenucontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.languagandusdinmobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mobilenavecontent {
  /* padding-bottom: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobileloginsignup {
  display: flex;
  gap: 20px;
}









@media screen and (max-width: 992px) {
  .btn_white {
    width: 100%;
  }
}
@media screen and (max-width: 992px) and (orientation: portrait) {
  .language {
    width: 50%;
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .language {
    width: 40%;
  }
}
@media screen and (max-width: 500px) {
  .googlepill,
  .fbpill {
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: center !important;
    width: 95% !important;
    padding: 1rem 2rem 1rem 2rem !important;
    border-color: black !important;
    border-width: 2.5px !important;
    font-weight: bold !important;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
}


@media screen and (min-width: 1128px) {
  .mobilemenucontainer {
    display: none;
  }
}



@media screen and (min-width: 1352px) {
  .hamburgericon {
    display: none;
  }
}

@media screen and (max-width: 1352px) {
  .loginandsignup,
  .upperbtn,
  .itemcontent {
    display: none !important;
  }
}

@media screen and (max-width: 392px) {
  .logo {
    height: 4rem;
    width: 13rem;
    /* display: none; */
  }
  .hamburgericon,
  .svg {
    font-size: 22px;
    cursor: pointer;
  }
}
@media screen and (min-width: 390px) and (max-width: 456px) {
  .logo {
    height: 2rem;
    width: 12rem;
  }
  .hamburgericon,
  .svg {
    font-size: 26px;
    cursor: pointer;
  }
}

@media screen and (min-width: 456px) and (max-width: 990px) {
  .logo {
    height: 2rem;
    width: 14rem;
  }

}
