.bg{
    height:40vh;
    width:100%;
    background-image:url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/toronto.jpg');
    background-size:cover;
    background-position: center;

}
.profilepic{
    height:12rem;
    width:12rem;
    border-radius: 50%;
    background-size:cover;
    position:relative;
    top: -6rem;
}

.background{
    background-color: rgb(255,255,255);
}
.text{
    font-family: "Roboto Slab", sans-serif;
}
.greycolor{
    color:rgb(179,181,183)
}
.links{
    text-decoration: none !important;
}
.queue_container{
    display: flex;
    overflow-x: scroll;
}
.sliderIconsLeft,.sliderIconsRight{
    cursor:pointer;
    position: absolute;
    top:50%;
}
.sliderIconsLeft{
    left:-5%;
}
.sliderIconsRight{
    right:-5%;
}
.sliderIconsLeft:hover,.sliderIconsRight:hover{
    opacity: 0.3;
}
.hi{
    background-color: rgb(0,0,0);
}
.contact {
    color:white;

}
.bg2{
    width:50%;
    height:0;
    padding-bottom:50%;
    border-radius: 50%;
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
}
.winfei{
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/Winfei.jpg');
}
.scroll{
    scroll-behavior: smooth;
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
    flex-grow:1
}
.scroll::-webkit-scrollbar{
    display: none !important;
    width:0 !important;
    background: transparent;
}

.slideRight{
    width:40%;
    height:0;
    padding-bottom:40%;
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/home_right.svg');
    background-size: cover;
    cursor:pointer
}
.slideLeft{
    width:40%;
    height:0;
    padding-bottom:40%;
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/home_left.png');
    background-size: cover;
    cursor:pointer
}
.w_25{
    min-width:30%;
    cursor:pointer;
    }
    .decor{
        text-decoration: none !important;
        color:black;
    }
    .decor:hover{
    color:black;
    }
    .img{
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/alie.jpeg');
    width:100%;
    height:0;
    padding-bottom: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    }
    .rating{
        color:rgba(173,58,63)
    }
    .courseinstructor{
        color:rgb(179,181,183)
    }
@media screen and (max-width:768px){
   
    .slideRight{
        width:20%;
        height:0;
        padding-bottom:20%;
        background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/home_right.svg');
        background-size: cover;
        transform: scale(4);
    }
    .slideLeft{
        width:20%;
        height:0;
        padding-bottom:20%;
        background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/home_left.png');
        background-size: cover;
        transform: scale(4);
    }
}
@media screen and (max-height:400px){
    .bg{
        height:100vh;
    }
}