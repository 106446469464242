/*  css for all left  items   */

.gohome {
  display: flex;
  align-items: center;
  padding:0rem 0rem 2rem 0rem
}

.backicon:hover {
  color: #2f80ed !important ;
  text-decoration: underline !important;
}

.commoniconn {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  cursor: pointer;
  padding: 0.7rem 1.4rem;
  text-decoration: none;
  border-radius: 1.5rem;
  transition: background-color 0.5s ease;
}

.commoniconn:hover {
  background-color: rgb(0, 0, 0, 0.1);
}

.textcolorsize {
  color: #8e8e8f !important;
  font-size: 1.1rem !important;
  white-space: nowrap !important;
  font-family: Poppins, sans-serif !important;
}
.activetext {
  color: white !important;
}

.active {
  background-color: rgb(0, 0, 0, 0.2) !important;
}

.canvascontainer {
  display: flex;
  margin-left: -3rem;
  box-shadow: 3px 0 10px rgba(192, 191, 191, 0.3);
  min-height: 100vh;
}

.sidbartogglebtn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}



.passwordvisiblity {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 80%;
  transform: translateY(-80%);
  font-size: 1.25rem;
}

.maincontainer {
  width: 100%;
}

.innercontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content:center;
}

.leftoptions {
  width: 13%;
}

.rightpart {
  width: 87%;
  padding: 2.5rem 3rem 3rem 3rem;
  position: relative;
  min-height: 100vh;
}
.bgheader {
  width: 100%;
  height: 9.5rem;
}

.profilecontainer {
  /* background-color: #ffffff; */
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: -3.125rem  auto 0 auto ;

}

.imgnamebox {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  /* width: 100vw; */

  /* border: 1px solid #646363; */
}

.piccontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  gap: 1rem;
  padding: 2rem 0 3.5rem 1.5rem;
}
.profilepic {
  width: 3.43rem;
  height: 3.43rem;

  background-size: contain;
}

.textcolor {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
}
.uploaddetails {
  /* background-color: red !important ;  */
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
}

.horizontalline {
  height: 1px;

  width: 90%;
  position: absolute;
  top: 66%;
  left: 6%;
}

.teacherhorizontalline {
  height: 1px;

  width: 95%;
  position: absolute;
  top: 58%;
  left: 2%;
}

.uploadimagresume {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-family: Poppins, sans-serif;
  /* background-color: green; */
}
.uploadimgcontainer {
  /* background-color: red; */
  border: 1px solid #cfcece;
  padding: 1.3rem;
}

.imagetext {
  font-size: 0.7rem;
  display: block;
  margin: 0;
  padding: 0.8rem 0.5rem 0.1rem 0.5rem;
  word-break: break-all;
  line-height: 1.5;
  width: 12.5rem !important ;
  /* color: #b7bbc4; */
}

.uploadresume {
  background-color: #307be8;
  color: #ffffff;
  padding: 0.4rem 0.9rem;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.uploadresume:hover {
  background-color: #5497fb;
}

.uploadPhoto {
  margin: 0;
  padding: 0;
  height: 11.87rem;
  width: 11.87rem;
  position: relative;
}
.uploadpictext {
  position: absolute;
  color: #ffffff;
  padding: 0.3rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
  width: 11.8rem;
  top: 84%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  cursor: pointer;
}

.userdetails {
  /* background-color: red; */
  width: 100%;
  align-self: flex-start;
  margin-bottom: 5rem;
  min-height: 100%;
}

.firstName {
  width: 49%;
}
.lastName {
  /* width: 48%; */
  width: calc(70% - 23%);

  margin-left: 1rem;
}

.passwordwidth {
  width: 65%;
  position: relative;
}

.labeltext {
  font-weight: 500;
  font-family: Poppins, sans-serif;
  padding-bottom: 0.3rem;
}
.inputtext {
  outline: none;
  padding: 0.4rem 0.4rem 0.4rem 0.7rem;
} 

.darktext {
  border: 1px solid #646363;
  background-color: #11151a;
  color: white;
}
.lighttext {
  border: 1px solid #dee0e2;
  background-color: #ffffff;
}
.darktext::placeholder {
  color: white;
}

.commonbtn {
  background-color: #ff6540;
  color: #ffffff;
  padding: 0.4rem 0.9rem;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
}

.savechanges {
  width: 10.5rem;
}
.changepasswordbtn {
  width: 11rem;
}

.commonbtn:hover {
  background-color: #f88c71;
}

.cahngepasscontainer {
  /* background-color: red; */
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}

@media screen and (max-width: 1650px) {
  .innercontainer {
    gap: 0rem;
  }

  .leftoptions {
    width: 17%;
  }

  .profilecontainer {
    width: 84%;
  }
}

@media screen and (max-width: 1200px) {
  .leftoptions {
    display: none;
  }
  .rightpart {
    width: 100%;
    padding: 1.5rem 1.8rem 0 1rem;
  }
  .bgheader {
    width: 100%;
    height: 9.5rem;
  }

  .profilecontainer {
    width: 86%;
  }

  .horizontalline{
      top:69% ;
  }
  .teacherhorizontalline{
    top: 60%;
  }
}

@media screen and (max-width: 970px) {


  .uploaddetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .uploadimagresume {
    align-self: center;
  }

 

  .profilecontainer {
    width: 100%;
  }

  .bgheader {
    width: 100vw;

    margin-left: -1rem;
    /* height: 7rem; */
  }

  .horizontalline {
    top: 76%;
    width: 94%;
    left: 1%;
  }
  .teacherhorizontalline {
    top: 68%;
  }
}

@media screen and (max-width: 600px) {
  .fullname {
    display: flex;
    flex-direction: column;
    grid-area: 0.5rem;
  }

  .firstName {
    width: 100%;
  }
  .lastName {
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
  }

  .passwordwidth {
    width: 80%;
  }
}

@media screen and (min-width: 1200px) {
  .sidbartogglebtn {
    display: none;
  }
}
