 /*  ScheduleXCalendar package css  */

.sx-react-calendar-wrapper {
  width: 100%;
  max-width: 100vw;
  height: 800px;
  max-height: 90vh;
}

.english-class {
  background-image: url("https://fullfocus.co/wp-content/uploads/2018/04/pen-on-paper.jpg") !important ;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: rgb(141, 139, 141) !important ;
  font-weight: 800 !important;
}

.Ielts-class{
    background-image: url("https://i.pinimg.com/736x/fa/c3/d9/fac3d957ecd9bcb6c6bbdcecbf8cbc74.jpg")  !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: rgb(31, 30, 31) !important ;
    font-weight: 800 !important;
}
.fench-class{
      background-image: url("https://cdn.pixabay.com/photo/2024/04/17/19/22/ai-generated-8702858_1280.png") !important;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      color: rgb(15, 14, 15) !important ;
      font-weight: 800 !important;
}
.spanish-class{
    background-image: url("https://img.freepik.com/free-vector/empty-school-class-background-video-conferencing_23-2148671385.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: rgb(32, 31, 32) !important ;
    font-weight: 800 !important;
}
.hindi-class{

  background-image: url("https://wallpapers.com/images/hd/virtual-classroom-background-cur1zhy27q5njenx.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: rgb(15, 14, 15) !important ;
  font-weight: 800 !important;
   
}
.other-class{
    background-image: url("https://fullfocus.co/wp-content/uploads/2018/04/pen-on-paper.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: rgb(22, 21, 22) !important ;
    font-weight: 800 !important;

}



/* .sx__event-modal, .sx__event-modal-default, .is-open {
  background-color: rgb(0, 255, 38) !important;
}

.sx__event-modal button {
  background-color: red;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.sx__event-modal button:hover {
  background-color: darkred;
} */

/* .sx__event-modal, .sx__event-modal-default, .is-open{
    position: relative;
    content: "delete";
} */



.sx__time-grid-event-title {
  font-weight: 800 !important;
  color: rgb(73, 106, 226);
}

.meeting-event {
  
  background-image: url("https://www.shutterstock.com/shutterstock/videos/1091298867/thumb/8.jpg?ip=x480");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.goodbg{
    background-image: url("https://cdn.shopify.com/s/files/1/0070/7032/articles/how_20to_20create_20an_20online_20course.jpg?v=1727451992");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: black !important ;
    font-weight: bold !important;
}

.sx__time-grid-event.sx__event{
        
}

.sx__time-grid-event-inner{

}


/* for hiding all header  */
/* .sx__calendar-header{
  display: none;
} */

/* for hiding Scheduler Week,day and Month part box */
.sx__view-selection-selected-item.sx__ripple {
  display: none;
}

/* for hiding forward and backward nvigation */
.sx__forward-backward-navigation {
  display: none;
}

/* this is for seeing sept-oct */
.sx__range-heading {
  display: none;
}


/* .sx__calendar {
  background-color: #373e48 !important;
}
.sx__date-input {
  background-color: #373e48 !important;
}
.sx__week-grid__date-axis {
  background-color: #373e48 !important;
}  */


/* for hiding  date picker */
/* .sx__date-picker-wrapper{
   display: none;
} */

/*  for hiding  Today at left corner */
/* .sx__today-button.sx__ripple{
   display: none;
} */




@media screen and (max-width:1200px) {
      .calenderapp{
        
         overflow: auto;
         white-space: nowrap;
      }

      .sx-react-calendar-wrapper {
        
        min-width: 1050px;
        height: 800px;
        max-height: 90vh;

        
      }
}