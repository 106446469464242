.maincontainer {
  margin: 2rem 1rem;
  padding: 2rem 2rem;
  }

.insideContainer {
  padding: 0rem 0.5rem;
}

.alltabContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 4rem;
}

.firsttwodiff {
  display: flex;
  align-items: center;
  gap: 3rem;
  cursor: pointer;
  white-space: nowrap;
}
.secondtwo {
  cursor: pointer;
  white-space: nowrap;
}

.singleItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  /* color: #6e7485; */
  gap: 0.2rem;
}

.numbertext {
  font-family: Arial, Helvetica, sans-serif;
  color: #23bd33;
}

.horizontaline {
  width: 100%;
  height: 2px;
  background-color: #e9eaf0;
  margin-top: 0.8rem;
  align-self: center;
}

.firsttwo{
  display: flex; 
  flex-direction: row;
  /* background-color: red; */
  width: 50%;
  justify-content: space-around;
}

.lastTwo{
   display: flex;
   flex-direction: row;
   /* background-color: green; */
   width: calc(100% - 50%);
   justify-content: space-around;
}



.activeclass {
  position: relative;
}

.activeclass::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleX(1.6);
  bottom: -1rem;
  height: 0.18rem;
  background-color: #ff6636;
  width: 80%;
  transition: transform 0.5s ease;
  z-index: 1;
}


@media screen  and (max-width:1200px) { 
  .alltabContainer{
      padding: 0 0rem;
  }

  .activeclass::after{
     width: 65%;
  }

  .firsttwodiff{
    gap: 1.5rem;
  }
  
}




@media screen and (max-width:991px) {
      .firsttwo{
        flex-direction: column-reverse;
        gap: 2rem;
        
        align-items: self-start;
        /* align-items: center; */
       
      } 

      .lastTwo{
           flex-direction: column-reverse;
           gap: 2rem;
           
           /* align-items : center; */
           align-items: self-start;
      }

      .activeclass::after{
         width: 80%;
         bottom: -0.875rem;
      }
}




@media screen and (max-width: 768px) {
  .maincontainer {
    padding: 1rem 1rem !important;
  }
}


@media screen and (max-width:576px) {
     .alltabContainer{
       flex-direction: column;
       gap: 1.5rem;
       /* background-color: red; */
       align-items: center;
     }

      .firsttwo{
        gap: 1.5rem;
     }

     .lastTwo{
   gap: 1.5rem;
     }
}

@media screen and (max-width:400px) {
   .alltabContainer{
    align-items: flex-start;
   }
}



