.coursestext {
  /* font-size: 3rem;  */
  font-size: clamp(1.8rem, 4vw, 3rem);
  font-weight: bold;
  color: #fd661f;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  position: relative;
  text-align: center;
  display: inline-block;
  /* margin-bottom: 1rem;   */
}

.coursecontent {
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

.mainCourseContainer{
   
   padding: 0 12rem;

}

.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ropesvg {
  position: absolute;

  transform: translateX(-50%);
  width: 90%;
  height: auto;
  top: 45%;
  left: 97%;
}

.coursebtn {
  background: white;
  color: #717072;
  border: 2px solid #c3c2c4;
  padding: 0.4rem 1.2rem;
  border-radius: 0.5rem;
  display: flex;
  font-family: Poppins,sans-serif;

  cursor: pointer;
  margin: 0 10px 0 10px;
  transition: background-color 0.5s ease, color 0.5s ease, border 0.5s ease;
}

.activeclass {
  background-color: #0b7077;
  color: white;
  border: none;
}

.coursescontainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 100%;
  /* background-color: green; */
  padding: 0 1.25rem;
}

.courseslistwrapper {
  /* background-color: red; */
  overflow-x: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.25rem;
}

.courseslist {
  display: flex;
  /* gap: 10px; */
  gap: 0.625rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px;
  scrollbar-width: none;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.courseslist::-webkit-scrollbar {
  display: none;
}

.scrollarrow {
  background: none;
  border: none;
  color: #f5690c;
  /* font-size: 35px; */
  font-size: clamp(25px, 4vw, 35px);
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.leftarrow {
  left: 0;
  /* left: 20px;  */
}

.rightarrow {
  right: 0;
  /* right: 20px;  */
}




/*////////////////////////////////// LatestArticle  css /////////////////// */

.latestContainer {
  margin-top: 8rem !important;
}

.latestHeadingCon {
  display: flex !important ;
  flex-direction: row !important;
  justify-content: space-between !important;
  padding-bottom: 3rem !important;
}

.allarticlebtn {
  outline: none;
  background-color: transparent !important;
  border: 3px solid #9d9d9d !important;
  padding: 0.5rem 1rem !important;
  font-family: Poppins, sans-serif !important;
  font-size: 1.4rem !important;
  border-radius: 2rem !important;
  color: #000000 !important;
}

.cardContainer {
  border: 1px solid #f2f2f2 !important;
  border-radius: 0.8rem !important ;
  cursor: pointer !important;
  transition: transform 0.3s ease !important;
}

.cardContainer:hover {
  transform: translateY(-60px) !important;
}
.cardContainer:hover .latestTitle {
  color: #ff782d !important;
}

.latestHeadtingTitle {
  font-size: 2rem !important;
  color: #000000 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-weight: bold !important;
  margin: 0 !important;
}
.explorearticletext {
  color: #555555 !important;
  font-size: 1.1rem !important;
}

.latestTitle {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  color: #000000 !important;
}

.latestDate {
  font-size: 1.2rem !important;
  color: #555555 !important;
  display: flex !important;
  align-items: center !important;
  gap: 0.3rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.latestDescription {
  color: #555555 !important;
  font-size: 1.1rem !important;
}

.mainOuterContainer {
  padding: 5rem 0rem 3rem 0rem !important;
}

.howItWorksContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  padding: 4rem 2.5rem;
  border-radius: 1.5rem;
  background: linear-gradient(
    to right,
    #d4defb,
    #d4defb,
    #ddd8fc,
    #e5d3fe,
    #e6d2fe
  );
}

.leftHOwitWork {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.rightHowitWork {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.letSHIwork {
  font-size: 1.4rem !important;
  color: #000000;

  font-weight: bold;
}

.vectorimageCon {
  width: 8rem;
  height: 8rem;
}

.vectorImage {
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
}

.iAmStudent {
  outline: none;
  background-color: transparent !important;
  border: 3px solid #ff782d !important;
  padding: 0.5rem 1rem !important;
  font-family: Poppins, sans-serif !important;
  font-size: 1.3rem !important;
  border-radius: 2rem !important;
  color: #ff782d !important;
  transition: background-color 0.3s ease !important ;
}

.activeClassbtn {
  background-color: #ff782d !important;
  color: white !important;
}

/*//////////////////////////// Carousel css////////////////////////        */

.carouselContainer {
  padding: 5rem 0rem 3rem 0rem;
}

.carouselFirstContainer {
  display: flex;
  flex-direction: row;
  background-color: #d8d5d0;
  padding: 4rem 2rem 0rem 8rem;
  align-items: center;
  justify-content: space-between;
} 

.carouselSecondContainer {
  display: flex;
  flex-direction: row;
  background: linear-gradient(to right, rgb(241, 187, 186), rgb(193, 222, 249));
  padding: 4rem 2rem 0rem 8rem;
  align-items: center;
  justify-content: space-between;
  border-radius:20px
} 


.leftPartCar {
  width: 55%;
}

.rightPartCar {
  width: calc(100% - 55%);

  display: flex;
  
  justify-content: 'flex-end';

}
.rightPartCar2 {
  width: 40%;

  display: flex;
  
  justify-content: 'flex-end';

}
.leftimageContainer {
  width: 550px !important;
  height: 550px !important;
  /* margin-left: auto; */

 

}
.secondleftImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* background-color: yellow; */
  
}

.headingLefttext {
  color: #000000;
  font-size: 2.8rem !important;
  font-family: "Kalam", cursive;
  font-weight: 600;
  font-style: normal;
  text-align: left;
}

.freetest {
  color: #ed5c33;
  padding-left: 0.5rem;
}
.leftdescripiton {
  font-family: Poppins, sans-serif !important;
  font-size: 1.3rem;
  color: #001826;
  text-align: left;
}


.takeTestContainer{
    /* background-color: red; */
    display: flex;
    align-self: flex-start;
}

.takeTestBtn {
  outline: none;
  background: transparent;
  color: #f28c43;
  border: 1px solid #f28c43;
  padding: 1rem 2.8rem;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 1.3rem;
  border-radius: 2rem;
  margin-top: 1.5rem;
   /* background-color: red; */

}
.takeTestBtn:hover {
  background-color: #e9e7e5;
  
}

.customArrow {
  background: none;
  border: none;
  color: #000;
  font-size: 1.8rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}


@media screen and (max-width:1500px) { 
    
  .mainCourseContainer{
            padding: 0 5rem;
  }
}






@media screen and (max-width: 1200px) {
  .howItWorksContainer {
    padding: 4rem 1.5rem;
  }

  .carouselFirstContainer{
    padding: 4rem 2rem 0rem 2rem;
  }
}



@media screen and (max-width: 996px) {
  .leftHOwitWork {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rightHowitWork {
    flex-direction: column;
  }

  .iAmStudent {
    font-size: 1.1rem !important;
  }

  .letSHIwork {
    font-size: 1.2rem !important;
  }
  .carouselFirstContainer{
    flex-direction: column;
    gap: 2rem;
  }
  .carouselSecondContainer {
    flex-direction: column;
    gap: 2rem;
  }

  .leftPartCar {
    width: 90%;
  }
  
  .rightPartCar, .rightPartCar2{
    width: 90%;
  }


}

@media screen and (max-width: 768px) {
  .courseslistwrapper {
    width: 100%;
    overflow-x: auto;
  }
  .courseslist {
    width: 100%;
    overflow-x: auto;
  }
}

@media screen and (max-width: 576px) {

  .mainCourseContainer{
    padding: 0 0.5rem;
}



  .coursecontent {
    padding: 0 0 0 1.4rem;
  }

  .howItWorksContainer {
    flex-direction: column !important;
  }

  .letSHIwork {
    padding-bottom: 2rem;
  }

  .latestHeadtingTitle {
    font-size: 1.5rem !important;
  }

  .allarticlebtn {
    font-size: 1.2rem !important;
    padding: 0.4rem 0.8rem !important;
  }

  .explorearticletext {
    font-size: 0.9rem !important;
  }

  .leftPartCar {
    width: 100%;
  }
  
  .rightPartCar, .rightPartCar2{
    width: 100%;
  }
     
 .headingLefttext{
    font-size: 2rem !important;
 }

 .leftdescripiton{
    font-size: 1.1rem !important;
 }

 .leftimageContainer{
     width: 420px !important;
     height: 420px !important;
 }



}
