.profilePic{
    width:12%;
    height:0;
    padding-bottom: 12%;
    border-radius: 50%;
    background-size: cover;
}

.bg_dark{
    /* background-color: rgb(20,20,20); */
    flex:0 0 auto;
}
.inputDiv{
    /* background-color: rgb(20,20,20); */
    flex:0 0 auto;
    height: auto;
}

.container{
    /* background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/bg_chat.jpeg'); */
    background-position: center;
    background-size: cover;
    border-left: 3px solid #eeeeee;
}

.threeIcon{
    cursor: pointer; 
    
}

.admin_container {
    background-color: rgb(27,31,36);
    flex:0 0 auto
}

.div2{
    max-width: 15%;
    width:auto;
    /* background-color: rgb(99,214,97); */
    cursor:pointer;
}

.bottomIcons{
    margin: 0rem 0.5rem;
    cursor: pointer;
}

.scrollMessages{
flex:1 1 auto;
overflow-y: scroll;
scrollbar-width: none;
}
.scrollMessages-webkit-scrollbar {
    display: none !important;
    width:0 !important;
}

@media screen and (max-width:992px){
    .profilePic{
        width:20%;
        height:0;
        padding-bottom: 20%;
        border-radius: 50%;
        background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/banvir.jpeg');
        background-size: cover;
    }
    
}

@media screen and (min-height:1000px) {
    .admin_container{
        min-height: 80vh;
    }
}