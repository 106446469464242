.picContainer{
    width: 80%;
    border:none !important;
}
.choose{
    width:100%;
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/tutor.gif');
    height:0;
    padding-bottom: 100%;
}
.book{
    width:100%;
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/trial2.mp4');
    height:0;
    padding-bottom: 100%;
}
.start{
    width:100%;
    background-image: url('https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/start.gif');
    height:0;
    padding-bottom: 100%;
}   
.choose,.book,.start{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.scholaristWorks{
    gap: 0.25rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    /* font-family:Poppins, sans-serif ; */
    font-size: clamp(23px ,5vw,45px);
    font-weight: 800;
    line-height: 0.5rem;
}
.lowertext{
    font-weight: 800;
    /* font-size: 24px; */
    font-size: clamp(0.7rem, 1.5vw + 1rem, 1.5rem);
}



@media screen and (max-width:768px){
    .picContainer{
        width:45%;
    }
}