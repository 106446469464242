.searchBarResults {
  cursor: pointer;
}
.searchBarResults:hover {
  background-color: skyblue;
}
.searchDropdown {
  position: absolute;
  z-index: 1;
  background-color: white;
  top: 2.36rem;
  left: 1em;
  width: 95% !important;
  height: 10rem;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgb(60, 60, 60);
}
.searchDropdownInvisible {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: white;
  top: 2rem;
  border-top: 2px solid lightgray;
  width: 100%;
}

/* new design css  */

/* nav bar or header css */

.loadingWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6rem;
 }


.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbaritems {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin: 0;
}

.leftitems {
  display: flex;
  align-items: center;
}

.hamburgericon {
  color: whitesmoke;
  font-size: 25px;
  display: flex;
  margin-right: 2rem;
}
.hamburgericon,
.svg {
  font-size: 32px;
  cursor: pointer;
}

.img_fit {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.logo {
  height: 4rem;
  width: 12.5rem;
  background-image: url("https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/scholarist_orange.png");
}

.rightitems {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.profileimg {
  width: 3.5rem;
  height: 3.5rem;
  background-position: center;
  border-radius: 1.75rem;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
}

.mobileproimg {
  width: 8rem;
  height: 8rem;
  margin-top: 2rem;
}

.browsepart {
  font-size: clamp(0.8rem, 4vw, 1rem);
  border-width: 1px;
  border-style: solid;
  border-radius: 0.2rem;
  padding: 0.4rem;
  font-family: Poppins, sans-serif;
  width: 13rem;
  flex-shrink: 0;
  margin-left: 1rem;
}
.WtoLearn {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 25rem;
  border: 1px solid;
  border-radius: 0.2rem;
  padding: 0.3rem 0.4rem;
  position: relative;
}

.MobiWtoLearn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 25rem;
  border: 1px solid;
  border-radius: 0.2rem;
  padding: 0.3rem 0.4rem;
  position: relative;
}

.MobBrowsepart {
  font-size: clamp(0.8rem, 4vw, 1rem);
  border-width: 1px;
  border-style: solid;
  border-radius: 0.2rem;
  padding: 0.4rem;
  font-family: Poppins, sans-serif;
  width: 16rem;
  flex-shrink: 0;
  /* margin-left: 1rem; */
}

.viewallinteacher{
  color:#2f80ed ;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
  font-family: Poppins,sans-serif;
  font-size: 1.2rem !important;
  white-space: nowrap
  ;
}

/* bottom part css  */

.maincontainer {
  margin: 2rem 1rem;
  padding: 0rem 1rem;
}

.insideContainer {
  display: flex;
  flex-direction: column;
}

.mycoursescalender {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.genralInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.31rem 0 0.31rem 1rem;
  margin-bottom: 1.5rem;
}

.sametextsinInfo{
  margin: 0;
  padding: 0;
  color:#8c8f97 ;
  font-size: 1rem;
  font-family: Poppins,sans-serif;
  white-space: nowrap;
}
.sameNumberTextInGen{
 margin: 0;
 padding: 0;
 font-family: Poppins,sans-serif;
 font-size: 1.1rem;
 font-weight: 500;
}


.boxinfo {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 3rem 0.5rem 1.5rem;
}

.iconbox {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
}

.mycourses {
  width: 72%;
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.gohome {
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  margin-bottom: 0.5rem;
}

.backicon:hover {
  color: #2f80ed !important ;
  text-decoration: underline !important;
}

.allCourse {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  /* max-height: 400px; */
  /* overflow: hidden; */
  /* transition: max-height 4s ease-in-out; */
}
/* smooth transition when user click view all  */
/* .expanded{
     max-height: 2000px;
     transition: max-height 4s ease-in-out;
  } */

.myCalender {
  width: calc(100% - 72%);
  padding: 0 0.5rem;
  height: 100%;
  color: white;
}

.calendarcontainer {
  width: 100%;
  background-color: transparent !important;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.gotoSchedule {
  background-color: #ed5c33;
  color: white;
  border: none;
  padding: 0.5rem 3rem;
  margin-top: 3rem;
  border-radius: 1.5rem;
  cursor: pointer;
}
.gotoSchedule:hover {
  background-color: #f67457;
}

.assignmentlesson {

  margin-top: 6rem;
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.assignment {
  width: 75%;
  overflow-x: auto;
}
.assignment::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.assignment::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: rgba(152, 149, 149, 0.267);
}

.upcomingless {
  color: white;
  width: calc(100% - 73%);
  position: relative;
}

/*========================= table css ================ */

.tableStyles {
  color: #dfe7f0;
  width: 100%;
  border-collapse: collapse;
  min-width: 700px; /* Ensure the table doesn't shrink too small */
}

.headerStyles {
  border: none;
  padding: 0 1.1rem 1.1rem 1.1rem !important  ;
  text-align: left;
white-space: nowrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
}

.cellStyles {
  white-space: nowrap;
  border-bottom: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.99rem;
  padding: 0.8rem 1.1rem !important ;
  vertical-align: middle !important;
}
.cessStatus {
  border-bottom: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.88rem;
  vertical-align: middle !important;
  border-radius: 4px !important;
  display: inline-block;
  padding: 0.1rem 0.3rem;
  text-align: center !important;
  margin-top: 0.6rem;
}
.completed {
  background-color: #d9eed1 !important ;
  color: #72ad5c !important ;
}
.upcoming {
  background-color: #cbe6f0 !important ;
  color: #4e7584 !important ;
}

.darktable tbody tr:nth-child(odd) {
  background-color: #353535;
}
.lighttable tbody tr:nth-child(odd) {
  background-color: #f5f6f8;
}

.eventdots {
  display: flex;
  justify-content: center;
  margin-top: 10px; 

}

.reddot {
  width: 5px;
  height: 5px;
  /* background-color: red; */
  border-radius: 50%;
  margin: 0 2px;

}

  @media screen and (max-width:1550px) {
    .genralInfo {
      display: flex;
      overflow-x: auto;
  
      white-space: nowrap;
      gap: 1rem;
      justify-content: flex-start;
  
      padding: 0;
      margin-left: 1.25rem;
    }
    .boxinfo {
      min-width: 14.3rem;
    }
    .genralInfo::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }
    .genralInfo::-webkit-scrollbar-thumb {
      border-radius: 0.5rem;
      background-color: rgba(152, 149, 149, 0.267);
    }
  }
  


@media screen and (max-width: 1410px) {
 

  .rightitems {
    display: none;
  }
  .browsepart {
    display: none;
  }
  .WtoLearn {
    display: none;
  }

  .mycoursescalender {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mycourses {
    width: 95%;
  }

  .myCalender {
    width: 50%;
  }

  .assignmentlesson {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
  .assignment {
    width: 85%;
  }
  .upcomingless {
    display: flex !important;

    width: 40%;
  }
}

@media screen and (max-width: 991px) {
  .assignment {
    width: 100%;
  }
  .upcomingless {
    width: 55%;
  }
}

@media screen and (max-width: 776px) {
  .maincontainer {
    padding: 1rem 0.5rem !important;
  }
  .insideContainer {
    padding: 0.5rem 0rem;
  }
  .mycourses {
    width: 100%;
  }

  .myCalender {
    width: 85%;
  }

  .upcomingless {
    width: 65%;
  }
}

@media screen and (max-width: 500px) {
      
    .genralInfo{
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        scrollbar-width: none;
      }

      
  .MobiWtoLearn {
    width: 18rem;
  }
  .MobBrowsepart {
    width: 14rem;
  }
  .myCalender {
    width: 95%;
  }
  .upcomingless {
    width: 96%;
  }
}

@media screen and (min-width: 1410px) {
  .hamburgericon {
    display: none;
  }
}

