.contactUsContainer {
  padding: 5rem 5rem;
}

.searchBox {
  /* background-color: red; */
  display: flex;

  align-items: center;
  justify-content: center;
}

.gohome {
  display: flex;
  align-items: center;
  width:'70%';
  padding: 1rem 1rem;
  margin: auto;
}

.backicon:hover {
  color: #2f80ed !important ;
  text-decoration: underline !important;
}

.searcharticles {
  /* margin-left: 2rem; */
  display: flex;
  align-items: center;
  gap: 0.5rem;

  border: 1px solid;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  position: relative;
  width: 50%;
}
.closebtncontact {
  position: absolute;
  left: 95%;
}

.lightPlaceholder::placeholder {
  color: #626262;
  font-size: 1.1rem !important;
}
.searchDropdowncontact {
  position: absolute;
  z-index: 1;
  top: 3rem;
  width: 80% !important;
  height: 10rem;
  overflow-y: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 0.32rem;
  background-color: white !important;
  border: 1px solid #d4d6d6 !important ;
  box-shadow: -10px 10px 8px rgba(192, 191, 191, 0.2),
    10px 10px 8px rgba(192, 191, 191, 0.2);
}

.searchBarResults {
  display: flex;
  /* flex-direction: column; */
  margin-top: 0.32px;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.8rem;
  font-family: Poppins, sans-serif;
}

.askyourQuestion {
  color: #ed5c33;
  line-height: 5px;
  letter-spacing: 0.1rem;
  font-size: clamp(0.93rem, 3vw, 1.12rem);
  font-weight: bold;
}

.contactFaqconta {
  padding: 1rem 5rem 6rem 5rem;
}

.freaq {
  width: 70%;

  padding: 1rem 1rem;
  margin: auto;
}

.faqes {
  font-weight: bold;
  color: #6f6b80;
  padding: 0rem;
  margin: 0rem;
  font-size: clamp(1.1rem, 3vw, 1.3rem);
}

.haveMoreQue {
  padding: 0rem 10rem 6rem 10rem;
}
.havemoreinsideContainer {
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.leftimage {
  width: 50%;
  margin-top: -1rem;
}
.imageinContactus {
  width: 75%;
  height: auto;
  object-fit: contain;
  background-position: center;
}

.rightinputpart {
  width: calc(100% - 50%);
}

.contatUstext {
  color: #545454;
  font-family: Poppins, sans-serif;
  /* font-size: 1.8rem;  */
  font-weight: 700;
  font-size: clamp(1.2rem, 3vw, 1.7rem);
}

.labeltext {
  font-weight: 600;
  font-family: Poppins, sans-serif;
  padding-bottom: 0.5rem;
  color: #545454;
  font-size: 1.2rem;
}

.inputtext {
  outline: none;
  padding: 0.4rem 0.4rem 0.4rem 0.7rem;
  border: 2px solid #9a9a9a;
  background-color: #ffffff;
  border-radius: 0.9rem;
  width: 80%;
}
.inputContainer {
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
}

.attachFile {
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #0066ff;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
}

.fileName {
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
}

.contactSubmit {
  background-color: #ff8e01;
  width: 45%;
  text-align: center;
  color: #ffffff;
  padding: 0.7rem 1rem;
  align-self: center;
  font-size: 1.3rem;
  cursor: pointer;
  font-weight: bold;
  border-radius: 0.8rem;
  letter-spacing: 1px;
}

.contactSubmit:hover {
  background-color: #f39c32;
}

.submitContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* /////////////chat bot css////////////////// */

.chat_icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* .modalGradient{
    background-image: linear-gradient(45deg,rgb(233,93,59) 45%,rgb(254, 208, 47));
} */

.chatbody {
  height: 40vh;
  /* background-color: red; */
}

.w_98 {
  width: 98%;
}

.avatar {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.messageUser {
  max-width: 78%;
  height: auto;
  background-color: rgb(92, 230, 107);
  color: white;
  min-height: 1em;
  font-family: Poppins,sans-serif;
  font-size: 1.1rem;
}
.messageSender {
  max-width: 78%;
  height: auto;
  background-color: rgb(69, 86, 104);
  color: white;
  min-height: 1em;
  font-family: Poppins,sans-serif;
  font-size: 1.1rem;
}

.scroll {
  height: 75vh;
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1 1 auto;
}

.fotterInputbox {
  /* border-radius: 1.4rem; */
  border-radius: 23px !important;
  padding: 0.8rem 1rem !important;
  border: 2px solid #979c9e !important;
  font-size: 1.1rem !important;
}

.sendIconCon {
  background-color: #ed5c33 !important;
  text-align: center;
  margin-left: 1rem !important;
  border-radius: 50% !important;
  padding: 0.5rem 0.5rem !important;
  color: #ffffff !important;
  cursor: pointer !important;
}

.emojiAndgirlCon {
  font-family: Poppins, sans-serif !important;
}

.girlImageCon{
   width: 130px;
   height: 130px;
}

.girlImageinChatBot{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.currenttime{
  color: #828587;     
  font-size: 1.1rem;
  
}

.allPreviMessCon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.emojiText {
  background-color: #f3f5f6 !important;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem !important;
  font-size: 1rem;
}

.aichatbotsecond {
  height: 70%;
  border-radius: 30px;
  overflow: hidden;
  
}

/* .aichatbot, .modal-body {
    max-height: calc(100% - 150px); 
    overflow-y: auto;
} */

/* .aichatbot, .modal-footer {
    border-top: 1px solid #dee2e6;
    padding: 10px;
} */

@media screen and (max-width: 1500px) {
  .contactUsContainer {
    padding: 5rem 1rem;
  }

  .searcharticles {
    width: 65%;
  }

  .contactFaqconta {
    padding: 1rem 2rem 6rem 2rem;
  }
  .freaq, .gohome{
    width: 90%;
  }


  .haveMoreQue {
    padding: 0rem 0rem 6rem 0rem;
  }
}

@media screen and (max-width: 968px) {
  .havemoreinsideContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .leftimage {
    width: 95%;
  }
  .imageinContactus {
    width: 70%;
  }

  .rightinputpart {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .searcharticles {
    width: 90%;
  }

  .freaq, .gohome{
    width: 100%;
    padding: 1rem 0rem;
  }

  .contactFaqconta {
    padding: 1rem 1rem 6rem 1rem;
  }

  .imageinContactus {
    width: 85%;
  }

  .rightinputpart {
    width: 100%;
  }

  .inputtext {
    width: 100%;
  }
}
