

.loadingContainer {
  text-align: center;
  margin-top: 5rem;
}

.noAssingnments{
  margin-top: 2rem;
  font-size: clamp(1.1rem ,3vw,1.4rem);
  color: #564ffd;
  font-family: Poppins,sans-serif;
}


.commontbutton {
  padding: 0.6rem 1.3rem;
  cursor: pointer;
  font-size: 0.9rem;
  text-align: center;
  outline: none;
  border: none;
  font-family: Poppins, sans-serif;
  border-radius: 5px;
}

.buyCourseFirst{
  text-align: center;
  font-size: clamp(1.3rem,3vw,1.1rem);
  font-weight: 600;
  margin-top: 5rem;
  min-height: 30vh;
  color: rgb(131, 129, 129);
}

.cancle {
  background-color: rbga(255, 255, 255, 0.3);
  color: black;
  font-weight: 600;
}
.cancle:hover {
  background-color: rgb(182, 178, 178);
}
.submit {
  background-color: #fd754c;
  color: white;
  font-weight: 600;
}
.submit:hover {
  background-color: #f95827;
}


.noResources{
  margin-top: 2rem;
  font-size: clamp(1.1rem ,3vw,1.4rem);
  color: #564ffd;
  font-family: Poppins,sans-serif;
}

.addResbtn {
  font-size: 1.7rem;
  background-color: #c5e8fe;
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  /* margin-left: 10rem; */ 
  margin-bottom: 2rem;
}

.addResbtn :hover {
  background-color: #d1e5f2;
  color: #3a3e47;
}



.assignment {
   
  overflow-x: auto;
  margin: 0rem auto;
  width: 80%;
  padding-bottom: 5rem;
  /* background-color: red; */
}
.assignment::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.assignment::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: rgba(152, 149, 149, 0.267);
}

.tableStyles {
  color: #dfe7f0;
  width: 100%;
  border-collapse: collapse;
  min-width: 700px; /* Ensure the table doesn't shrink too small */
}

.darktable tbody tr:nth-child(odd) {
  background-color: #353535;
}
.lighttable tbody tr:nth-child(odd) {
  background-color: #f5f6f8;
}

.headerStyles {
  border: none;
  padding: 0 1.1rem 1.1rem 1.1rem !important  ;
  text-align: left;
  font-family: Poppins,sans-serif;
  font-size: 1rem;
  white-space: nowrap;
}

.cellStyles {
  border-bottom: none;
  white-space: nowrap;
    font-family: Poppins,sans-serif;
  font-size: 0.99rem;
  padding: 0.8rem 1.1rem !important ;
  vertical-align: middle !important;
  
}
.cessStatus {
  border-bottom: none;

    font-family: Poppins,sans-serif;
  font-size: 0.88rem;
  vertical-align: middle !important;
  border-radius: 4px !important;
  display: inline-block;
  padding: 0.1rem 0.3rem;
  text-align: center !important;
  margin-top: 0.6rem;
}

.completed {
  background-color: #d9eed1 !important ;
  color: #72ad5c !important ;
}
.view {
  background-color: #cbe6f0 !important ;
  color: #4e7584 !important ;
  padding: 0.2rem 1rem; 
  cursor: pointer;
}

.activeAssignment{
    background-color:#6bccef!important ;
}
 
  .arrupdown{
       font-size: 1.2rem;
  }

  .purchaseCourse{
     /* background-color: red; */
     padding-top: 2rem;
     padding-left: 0.5rem;
     padding-right: 0.5rem;
  }


  .popovertext{
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 0.3rem;
     align-self: flex-start;
     color: #667073;
     cursor: pointer;

  }
  .popoverdelete{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    align-self: flex-start;
    color: #f0310b;
    cursor: pointer;
  }

  .uploadfiles{
      /* background-color: red;  */
      margin-top: 4rem;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;

  }

  .pdffile{
      /* align-self: center; */
      /* background-color: yellow; */
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .resume{
    width: 70%;
    /* background-color: red; */
    border: 2px solid #959798;
    
  }

  .btnContainer{
    display: flex;
    flex-direction: row;
    gap: 1rem;
     margin-top: 3rem;
     overflow-x: auto;
    align-self: center;
      }
  

  .commonbtn {
    background-color: #ff6636;
    color: #ffffff;
    padding: 0.7rem 1.2rem;
    letter-spacing: 1px;
    cursor: pointer;
    font-size: 1.2rem;
    outline: none;
    border: none;
    font-family: Poppins, sans-serif;
    white-space: nowrap;
    align-self: center;
    
  }

  .commonbtn:hover{
      background-color: #fb754c;
  }


  .resourcetitlecontainer{
      padding: 0 10rem;
      /* background-color: green; */
  }

 .resourcetitle{
      font-size: 2rem;
      font-family: Poppins,sans-serif;
      font-weight: 600;
      
 }
 .filetypeupload{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  font-family: Poppins,sans-serif;
  letter-spacing: 0.5px;
  margin-bottom: 2px;
  /* color: #647b9f; */
 }

 .resourcedes{
  margin-top: 1rem;
   width: 50%;
   font-family: Poppins,sans-serif; 
   display: flex;
   align-items: center;
   /* word-break: normal; */
  
 }


 .videoContainer{
      /* background-color: red;    */
      width: 800px ;
      height: 430px;
 }


 @media screen and (max-width:1450px) {
    .resourcedes{
      width: 70%;
    }     
 }

 @media screen and (max-width:1200px)  {
     .resourcetitlecontainer{
        padding: 0 5rem;
     }
 }




@media screen and (max-width: 991px) {
  .assignment {
    width: 90%;
  }
  .pdffile{
    width: 100%;
  }

  .resume{
    width: 90%;
  }

  .resourcedes{
    width: 80%;
  } 

   .videoContainer{
      width: 600px;
      height: 300px;
   }



}

@media screen and (max-width:870px) {
     
  .resourcetitlecontainer{
    padding: 0 1.5rem;
    margin-top: 3rem;
 }
  .resourcedes{
    width: 95%;
  } 

  .videoContainer{
    width: 470px;
    height: 250px;
 }
}

@media screen  and (max-width:576px) {
   .btnContainer{
     flex-direction: column;  

   }
   .btnContainer button:first-child {
    order: 3; /* Set the highest order to move it last */
  }


   .pdffile{
     padding-right: 1rem;
   }

   .filetypeupload{
    flex-direction: column-reverse;
    gap: 0.5rem;
    /* align-self: flex-start; */
    align-items: flex-start;
   }

   .videoContainer{
    width: 335px;
    height: 200px;
 }

 .resourcetitle{
   font-size: 1.6rem;
 }
}