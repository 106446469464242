.maincontainer {
    margin: 1rem 0rem;
    padding: 1rem 0rem;
    /* background-color: red; */
  }
  
  .insideContainer {
    padding: 0.5rem 5rem;
    /* background-color: green; */
    display: flex;
    flex-direction: row;
  }
  
  .truncated {
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    }
 
  
  .CorsesorTeacherContainer {
   
    align-self: center;
    padding: 0 0 0 2rem;
    margin: 0 auto;

  }
  
  .Goback{
     display: flex;
     align-items: center;
     gap: 0.5rem;
     cursor: pointer;
     padding-left: 1rem;
     font-family: Poppins,sans-serif;
  }
  
  
  
  .toggleBtn {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }
  
  .coursebtn {
    background: white;
    color: #717072;
    border: 0.12rem solid #c3c2c4;
    padding: 0.18rem 1.18rem;
    border-radius: 0.62rem;
    display: flex;
    cursor: pointer;
    font-family: Poppins, sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
    align-items: center;
    justify-content: center;
  }
  
  .activeclass {
    background-color: #9e35cc;
    color: white;
    border: none;
  }
  
  .coursecontent {
    margin: 1.5rem auto;
    /* margin: 2rem auto 3rem auto !important; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spinner {
    /* background-color: red; */
    height: 100%;
    position: relative;
    top: 15%;
    left: 45%;
  }
  
  
  
 
  
  @media screen and (max-width: 1590px) {
   
    .CorsesorTeacherContainer {
      width: 100%;
      padding: 0 2rem 0 3rem;
    }
    .languagefilter {
      width: 100%;
    }
    .Goback{
      padding-top: 1rem !important;
    }
  }

@media screen and (max-width:1200px) {
    .insideContainer{
         padding: 0.5rem 1rem;
    }
}

  
  @media screen and (max-width: 500px) {
    .insideContainer {
      padding: 0;
    }
    .CorsesorTeacherContainer {
      padding: 0 0 0 2rem;
    }
  
    .toggleBtn {
      overflow-x: auto;
      white-space: nowrap;
      padding-bottom: 0.5rem;
      padding-right: 0.5rem;
      scrollbar-width: none;
      
    }
  
  
  }
  
 
  